/* -----------------------------------------------------
    Gestion de u layout principal de la page
--------------------------------------------------------
*/

import React from 'react';
import MaskLoader from './MaskLoader';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DrawerManagement from './Drawer/index';
import ModalLogout from './ModalLogout';
import Sidebar from './SideBar/index';
import { menuDataSource } from './../../config/config';

function Layout({ Component, ...props }) {

    const displayLoader = useSelector(state => state.app.displayLoader);

    return (
        <React.Fragment>
            <MaskLoader visible={displayLoader} />
            <Sidebar data={menuDataSource} />

            <main className="main-content">
                <Component />
            </main>

            {/* Gestion des panneaux optionnels */}
            <DrawerManagement />

            <ModalLogout />

        </React.Fragment>
    )
}

Layout.propTypes = {
    Component: PropTypes.object,
};

export default Layout;
