import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import InformationMessage from './InformationMessage';
import Loader from './Loader.jsx';

function ModalLogout() {

    const isModalVisibale = useSelector(state => state.app.isModalLogoutVisible);

    useEffect(() => {

        if (isModalVisibale) {
            setTimeout(() => {
                document.location.href = '/logout'; // LOGOUT!!!
            }, 2000);
        }
    }, [isModalVisibale]);

    return (

        <Modal className="modal-logout" open={isModalVisibale} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <InformationMessage type="information">
                Votre session a expiré...
                <Loader fullHeight={0} style={{ transform: 'scale(0.5)' }} />
            </InformationMessage>
        </Modal>

    )
}

export default ModalLogout;
