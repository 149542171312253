/**
 * SAGA est un middleware permettant de gérer les appels asynchrones AJAX
 * pour ensuite mettre à jour les DATA du STORE de REDUX
 */
import { all } from 'redux-saga/effects';
import * as auth from './modules/auth';
import * as demandes from './modules/demandes';
import * as mails from './modules/mailsSaga';

export default function* rootSaga() {
    yield all([auth.watcherAuth(), demandes.watcherDemandes(), mails.watcherMails()]);
}
