import moment from 'moment';

export class UtilsDate {

    /**
     * Conversion d'un objet time en secondes
     * @param {object} timeData { hours, minutes } 
     */
    static convertTimeDataToSeconds = ({ hours, minutes }) => {
        return hours * 3600 + minutes * 60;
    }

    /**
     * Conversion d'une durée en secondes en durée heures / minutes
     * @param {*} timestamp 
     * @param {*} dataFormat 
     */
    static convertSecondsToTimeLength = (timestamp, dataFormat = false) => {

        const operator = timestamp < 0 ? '-' : '';

        timestamp = Math.abs(timestamp);

        let hours = Math.floor(timestamp / 60 / 60);
        let minutes = Math.floor(timestamp / 60) - (hours * 60);
        let seconds = timestamp % 60;

        hours = (Math.abs(hours) < 10) ? "0" + hours : hours;
        minutes = (Math.abs(minutes) < 10) ? "0" + minutes : minutes;
        seconds = (Math.abs(seconds) < 10) ? "0" + seconds : seconds;

        if (!dataFormat) {
            return operator + hours + ':' + minutes;
        } else {
            return {
                operator,
                hours,
                minutes,
                seconds
            }
        }
    }


    /**
     * Conversion seconde en heure (horloge)
     * @param {*} duration 
     */
    static convertNumericToTime = (duration) => {

        const operator = duration < 0 ? '-' : '';

        duration = Math.abs(duration);

        let milliseconds = parseInt((duration) / 100),
            seconds = Math.floor((duration) % 60),
            minutes = Math.floor((duration / (60)) % 60),
            hours = Math.floor((duration / (60 * 60)) % 24);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return operator + hours + ":" + minutes; // + ":" + seconds + "." + milliseconds;
    }

    static getPublicHolidaysByYear = (year) => {
        const JourAn = new Date(year, "00", "01")
        const FeteTravail = new Date(year, "04", "01")
        const Victoire1945 = new Date(year, "04", "08")
        const FeteNationale = new Date(year, "06", "14")
        const Assomption = new Date(year, "07", "15")
        const Toussaint = new Date(year, "10", "01")
        const Armistice = new Date(year, "10", "11")
        const Noel = new Date(year, "11", "25")
        //var SaintEtienne = new Date(an, "11", "26")

        const G = year % 19
        const C = Math.floor(year / 100)
        const H = (C - Math.floor(C / 4) - Math.floor((8 * C + 13) / 25) + 19 * G + 15) % 30
        const I = H - Math.floor(H / 28) * (1 - Math.floor(H / 28) * Math.floor(29 / (H + 1)) * Math.floor((21 - G) / 11))
        const J = (year * 1 + Math.floor(year / 4) + I + 2 - C + Math.floor(C / 4)) % 7
        const L = I - J
        const MoisPaques = 3 + Math.floor((L + 40) / 44)
        const JourPaques = L + 28 - 31 * Math.floor(MoisPaques / 4)
        const Paques = new Date(year, MoisPaques - 1, JourPaques)
        const VendrediSaint = new Date(year, MoisPaques - 1, JourPaques - 2)
        const LundiPaques = new Date(year, MoisPaques - 1, JourPaques + 1)
        const Ascension = new Date(year, MoisPaques - 1, JourPaques + 39)
        const Pentecote = new Date(year, MoisPaques - 1, JourPaques + 49)
        const LundiPentecote = new Date(year, MoisPaques - 1, JourPaques + 50)

        return [
            { value: 1, label: "Jour de l'an", formatedDate: moment(JourAn).format('DD/MM/YYYY'), date: moment(JourAn).format('YYYYMMDD') },
            //{ value: 2, label: 'Vendredi Saint', date: VendrediSaint, formatedDate: moment(VendrediSaint).format('DD/MM/YYYY') },
            //{ value: 3, label: 'Pâques', date: Paques, formatedDate: moment(Paques).format('DD/MM/YYYY') },
            { value: 4, label: escape('Lundi de Pâques'), formatedDate: moment(LundiPaques).format('DD/MM/YYYY'), date: moment(LundiPaques).format('YYYYMMDD') },
            { value: 5, label: escape('Fête du travail'), formatedDate: moment(FeteTravail).format('DD/MM/YYYY'), date: moment(FeteTravail).format('YYYYMMDD') },
            { value: 6, label: 'Victoire 1945', formatedDate: moment(Victoire1945).format('DD/MM/YYYY'), date: moment(Victoire1945).format('YYYYMMDD') },
            { value: 7, label: 'Ascension', formatedDate: moment(Ascension).format('DD/MM/YYYY'), date: moment(Ascension).format('YYYYMMDD') },
            //{ value: 8, label: 'Pentecôte', date: Pentecote, formatedDate: moment(Pentecote).format('DD/MM/YYYY') },
            { value: 9, label: escape('Lundi de Pentecôte'), formatedDate: moment(LundiPentecote).format('DD/MM/YYYY'), date: moment(LundiPentecote).format('YYYYMMDD') },
            { value: 10, label: escape('Fête nationale'), formatedDate: moment(FeteNationale).format('DD/MM/YYYY'), date: moment(FeteNationale).format('YYYYMMDD') },
            { value: 11, label: 'Assomption', formatedDate: moment(Assomption).format('DD/MM/YYYY'), date: moment(Assomption).format('YYYYMMDD') },
            { value: 12, label: 'Toussaint', formatedDate: moment(Toussaint).format('DD/MM/YYYY'), date: moment(Toussaint).format('YYYYMMDD') },
            { value: 13, label: 'Armistice', formatedDate: moment(Armistice).format('DD/MM/YYYY'), date: moment(Armistice).format('YYYYMMDD') },
            { value: 14, label: escape('Noël'), formatedDate: moment(Noel).format('DD/MM/YYYY'), date: moment(Noel).format('YYYYMMDD') }
        ];
    }

    /**
     * Calcul la somme d'une liste de tems en heure
     * @param {array of string} listTimes [03:30, 04:00] 
     */
    static sumTimes = (listTimes, formatTime = 'T') => {
        let hours = 0;
        let minutes = 0;

        listTimes.forEach(time => {
            if (time) {
                const data = time.split(':');
                if (data.length === 2) {
                    hours += +data[0];
                    minutes += +data[1];
                }
            }
        });

        const totalMinutes = hours * 60 + minutes;

        if (totalMinutes === 0)
            return formatTime === 'T' ? '00:00' : 0;
        else
            return formatTime === 'T' ? this.timeConvert(totalMinutes) : totalMinutes;
    };

    static timeConvert = (timeInMin) => {
        let hours = (timeInMin / 60);
        let rhours = Math.floor(hours);
        rhours = rhours < 10 ? '0' + rhours : rhours;
        let minutes = (hours - rhours) * 60;
        let rminutes = Math.round(minutes);
        rminutes = rminutes < 10 ? '0' + rminutes : rminutes;
        return `${rhours}:${rminutes}`;
    };

    /**
     * Retourne la liste des jours en fonction du mois et de l'année
     * @param {string} year 
     * @param {string} month 
     */
    static getDaysArrayByMonth = (year, month, fullDate = false) => {
        const myDate = moment(`${year}-${month}`, 'YYYY-MM');
        const daysInMonth = moment(myDate).daysInMonth();

        if (month < 10) month = '0' + month;

        if (fullDate) {
            return Array.from({ length: daysInMonth }, (v, k) => `${k + 1 < 10 ? '0' + (k + 1) : k + 1}/${month}/${year}`);
        } else {
            return Array.from({ length: daysInMonth }, (v, k) => k + 1);
        }

    }


    /**
     * Retourne une date
     * @param {*} week 
     * @param {*} year 
     */
    static getDateFromWeek = (week, year, dateFormat = 'DD/MM/YYYY') => {
        return moment().year(year).week(week).startOf('week').format(dateFormat);
    }

    /**
     * Retourne un objet avec les composantes d'un temps (heure:min:sec)
     * @param {Date} currentTime 
     */
    static getTimeData = (currentTime = new Date().toLocaleTimeString()) => {

        const timeData = currentTime.split(':');

        return {
            hour: timeData[0],
            min: timeData[1],
            sec: timeData[2]
        };
    }

    /**
     * Récupère la liste de jours entre 2 dates données
     * @param {string} startDate 
     * @param {string} endDate 
     */
    static enumerateDaysBetweenDates = (startDate, endDate) => {
        var dates = [];

        var currDate = moment(startDate).startOf('day');
        var lastDate = moment(endDate).startOf('day');

        dates.push(currDate.clone().toDate())

        while (currDate.add(1, 'days').diff(lastDate) <= 0) {
            dates.push(currDate.clone().toDate());
        }

        return dates;
    };

    /**
     * Convertit en durée
     * @param {string} hour ex: 10:45 
     */
    static convertTime = (timeData, unity = 'hour') => {

        if (!timeData) return { hour: 0, minutes: 0, timeInHour: 0, timeInMinutes: 0 };

        const timeDate = timeData.split(':');
        if (timeDate.length === 2) {
            const hours = +timeDate[0];
            const minutes = +timeDate[1];
            return {
                hours,
                minutes,
                timeInHour: hours + Math.round(35 * 60) / 10000,
                timeInMinutes: hours * 60 + minutes
            };
        } else {
            return { hour: 0, minutes: 0, timeInHour: 0, timeInMinutes: 0 };
        }

    };

    /**
     * 
     * @param {string} startDate format date : 'YYYY-MM-DD' 
     * @param {*} endDate format date : 'YYYY-MM-DD'
     */
    static enumerateWeeksBetweenDates = (startDate, endDate, formatAutocompletion = false) => {
        startDate = moment(startDate).startOf('week').format('YYYY-MM-DD');
        endDate = moment(endDate).startOf('week').format('YYYY-MM-DD');

        const weeks = [];
        while (startDate <= endDate) {

            const numWeek = moment(startDate, 'YYYY-MM-DD').week();

            if (!formatAutocompletion) {
                weeks.push(numWeek);
            } else {
                weeks.push({ value: numWeek, label: numWeek.toString() });
            }

            startDate = moment(startDate).add(7, 'days').format('YYYY-MM-DD');
        }
        return weeks;
    }

}

export class Utils {

    static getIPLocal = function () {
        var ip = false;
        window.RTCPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection || false;

        if (window.RTCPeerConnection) {
            ip = [];
            var pc = new RTCPeerConnection({ iceServers: [] }), noop = function () { };
            pc.createDataChannel('');
            pc.createOffer(pc.setLocalDescription.bind(pc), noop);

            pc.onicecandidate = function (event) {
                if (event && event.candidate && event.candidate.candidate) {
                    var s = event.candidate.candidate.split('\n');
                    ip.push(s[0].split(' ')[4]);
                }
            }
        }

        return ip;
    }

    static displayNumber = function (value, precision = 2) {
        if (value % 1 !== 0) {
            value = (Math.round(value * 100) / 100).toFixed(precision);
        } else {
            value = Math.round(value * 100) / 100;
        }
        return value % 1 === 0 ? parseInt(value) : value;
    }

    static displayRightsValue = function (data) {
        return !isNaN(data) ? Math.ceil(data * 10) / 10 : 0;
    }

    /**
     * Formate les données des
     * @param {*} rawData 
     */
    static formatDataForCSV = function (rawData, fields = []) {

        let csvData = [];

        fields = ['AUTEUR_ETAPE', 'DATE_DEBUT', 'DATE_FIN', 'COMMENTAIRE', 'DATE_CLOTURE',]

        csvData.push(fields);

        if (Array.isArray(rawData) && rawData.length > 0) {

            rawData.forEach(line => {

                let formattedLine = []
                fields.forEach(field => {
                    formattedLine.push(line[field]);
                })
                csvData.push(formattedLine)
                //csvData.push(Object.values(line));
            })

        }

        return csvData;

    }

    static calculateDistanceBeetween2Points = function (point1, point2) {
        var dLat = (point1.latitude - point2.latitude) * Math.PI / 180;
        var dLon = (point1.longitude - point2.longitude) * Math.PI / 180;
        var a = 0.5 - Math.cos(dLat) / 2 + Math.cos(point2.latitude * Math.PI / 180) * Math.cos(point1.latitude * Math.PI / 180) * (1 - Math.cos(dLon)) / 2;

        return Math.round(6371000 * 2 * Math.asin(Math.sqrt(a))) / 1000;
    }

    static checkIsLocationInsidePerimeter = function (location, refPoint, allowedDistance) {
        const distance = Math.abs(this.calculateDistanceBeetween2Points(location, refPoint));
        return distance <= allowedDistance ? true : false;
    }

    static manageRequestError = function (error) {
        // if (DEBUG === 1) return false;
        console.log("error management : ", error);
        if (error.request !== undefined && (error.request.status === 401 || error.request.status === 500)) {
            console.log("error 401 : logout");
            //put(push('/logout'));
            //alert("session time out");
            //document.location.href = '/logout'; // LOGOUT!!!
            //yield put(setModalLogoutVisible());
        }
    }

    static getParameterByName = function (name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2]);
        //return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    static range = function (start, end) {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }

    static rangeListAutocompletion = function (start, end) {
        return Array(end - start).fill().map((_, idx) => { return { value: idx + 1, label: (idx + 1).toString() } })
    }

    // Mettre la première lettre d'une chaîne en majuscule
    static capitalize = function (str1) {
        return str1.charAt(0).toUpperCase() + str1.slice(1);
    }

    static arrayToObject = (array, keyField) =>
        array.reduce((obj, item) => {
            obj[item[keyField]] = item
            return obj
        }, {})


    // Permet de lire les variables d'un fichier .ini et de les lire en JS
    static parseINIString(data) {
        var regex = {
            section: /^\s*\[\s*([^\]]*)\s*\]\s*$/,
            param: /^\s*([^=]+?)\s*=\s*(.*?)\s*$/,
            comment: /^\s*;.*$/
        };
        var value = {};
        var lines = data.split(/[\r\n]+/);
        var section = null;
        lines.forEach(function (line) {
            if (regex.comment.test(line)) {
                return;
            } else if (regex.param.test(line)) {
                var match = line.match(regex.param);
                if (section) {
                    value[section][match[1]] = match[2];
                } else {
                    value[match[1]] = match[2];
                }
            } else if (regex.section.test(line)) {
                var match = line.match(regex.section);
                value[match[1]] = {};
                section = match[1];
            } else if (line.length === 0 && section) {
                section = null;
            };
        });
        return value;
    }

    /**
     * Insert une chaîne de caractères à une position donnée - ex pour les heures
     * @param {string} str : chaîne à modifier 
     * @param {integer} index : position où la chaîne sera ajoutée
     * @param {string} value : chaîne à ajouter
     */
    static insert(str, index, value) {
        if (typeof str === 'undefined') {
            return '';
        } else {
            return str.substr(0, index) + value + str.substr(index);
        }
    }

    static formatTime(data) {
        if (data === undefined) return '-';
        return this.insert(data, 2, ':')
    }

    static getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    static formatListIds(elts, fieldname, separator = ',') {
        let temp = [];
        try {
            elts.forEach(elt => {
                temp.push(elt[fieldname]);
            })
            return temp.join(separator);
        } catch (error) {

        }
    }

}
