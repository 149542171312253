import React from 'react';
import PropTypes from 'prop-types';

const LogoCompany = ({ classExtended }) => {

    const gotoHome = (e) => {
        document.location = "#";
    };

    return (
        <div className={`logo-company ${classExtended}`} onClick={(e) => gotoHome(e)}>
            scepia
            <div className="square"></div>
            <div className="square"></div>
            <div className="square"></div>
        </div>
    )
};

LogoCompany.propTypes = {
    classExtended: PropTypes.string
};

export default LogoCompany;