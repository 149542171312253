import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import Grid from '@mui/material/Grid';

const MyPagination = ({ totalDemande, setDemandes, demandesStore, demandes, pageEncours, setpageEncours }) => {
    // Nombre d'item à afficher
    const indontation = 9;

    // Page affiché
    const [nbpage, setnbPage] = useState(Math.ceil(totalDemande / indontation));

    // Page affiché
    const [page, setPage] = useState(1);
    // Page précedente
    const [pagePrecedente, setpagePrecedente] = useState(pageEncours);

    useEffect(() => {
        // 1 = page à afficher
        // Chaque initialisation du comosant on revient au premiere id
        let _idItem = 1;
        let _lastIdItem = _idItem + indontation;

        setDemandes(
            demandesStore.filter((demande, i) => {
                if (i >= _idItem - 1 && i < _lastIdItem) return demande;
            })
        );

        // On se remet sur la premiere page des demandes
        //setPage(1);
        setpageEncours(pageEncours);

        // On remet à jour le nombre de page de la pagination
        setnbPage(Math.ceil(totalDemande / indontation));
    }, [demandesStore]);

    // Use effect pour mettre à jour les items à affcicher en fonction de la page en cours
    useEffect(() => {
        let _idItem = pageEncours * indontation - indontation;
        let _lastIdItem = pageEncours * indontation;

        console.log('_idItem', _idItem);
        console.log('_lastIdItem', _lastIdItem);

        // On récupére les demaandes de notre store et on les trie pour n'afficher que celles de la page en cours
        setDemandes(
            demandesStore.filter((demande, i) => {
                // -1 car i commence à 0 et _idItem -1
                if (i > _idItem - 1 && i < _lastIdItem) return demande;
            })
        );

        setpagePrecedente(pageEncours);
    }, [pageEncours]);
    return (
        <>
            <Grid item xs={12} className="pagination">
                <Pagination count={nbpage} page={pageEncours} onChange={(event, value) => setpageEncours(value)} variant="outlined" color="primary" />
            </Grid>
        </>
    );
};

export default MyPagination;
