import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { Grid, Button, TextField, Card, CardContent, Radio, RadioGroup, FormControlLabel, FormControl, CardActions, Divider } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { API_GET_TYPE_APPEL, API_GET_CLIENT_ALL, API_GET_DEMANDES, API_GET_PRODUIT, API_GET_SOCIETE_ALL, API_GET_TEC_ALL, API_KEY, API_GET_CLIENT_SOCIETE } from '../../../../config/config';
import { setLoaderHidden, setLoaderVisible } from '../../../../redux/actions/actionsApp';
import ComboApi from '../comboApi';
import {  toast } from 'react-toastify';
import { demandeRequeste, demandeUpdateFiltres } from '../../../../redux/actions/actionDemande';
import FormLabel from '@mui/material/FormLabel';
import { appelApiGet } from '../../../../config/utils/apiFunction';
import { useLocation } from 'react-router-dom';

toast.configure();

export function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const DemandesSearch = () => {
    /**************************** HOOKS ********************************/
    const dispatch = useDispatch();
    const urlQuery = useQuery();

    /**************************** DONNEE RECUPERE DEPUIS LE STORE ********************************/
    const account = useSelector((state) => state.modAuth.account);
    const demandes = useSelector((state) => state.demandes.demandes);
    const filtres = useSelector((state) => state.demandes.filtresRecherche);

    /**************************** STATE LOCALE INPUT ********************************/

    // Récuperation des query de l'url pour les filtres
    const queryOrdre = urlQuery.get('ticketRech');
    const querydateRech = urlQuery.get('dateRech');
    const querydateFinRech = urlQuery.get('dateFinRech');
    const querycontientRech = urlQuery.get('contientRech');
    const queryclotRech = urlQuery.get('clotRech');
    const querydestinataireRech = urlQuery.get('destinataireRech');
    const querydemandeurRech = urlQuery.get('demandeurRech');
    const querysocieteRech = urlQuery.get('societeRech');
    const querytypeAppelRech = urlQuery.get('typeAppelRech');
    const queryproduitRech = urlQuery.get('produitRech');
    const querytri = urlQuery.get('tri');

    const [ordre, setOrdre] = useState(queryOrdre ? queryOrdre : filtres && filtres.ordre ? filtres.ordre : null);
    const [dateDeb, setDateDeb] = useState(querydateRech ? querydateRech : filtres && filtres.dateDeb ? filtres.dateDeb : /* moment().startOf('month').format('YYYY-MM-DD') */ '');
    const [dateFin, setDateFin] = useState(querydateFinRech ? querydateFinRech : filtres && filtres.dateFin ? filtres.dateFin : /*moment().format('YYYY-MM-DD') */ '');
    const [contains, setContains] = useState(querycontientRech ? querycontientRech : filtres && filtres.contains ? filtres.contains : null);
    const [closingCall, setClosingCall] = useState(queryclotRech ? queryclotRech : filtres && filtres.closingCall ? filtres.closingCall : '1');
    const [typeTri, setTypeTri] = useState(querytri ? querytri : filtres && filtres.typeTri ? filtres.typeTri : '1');

    // Variables
    const superUtilisateur = account.niveau_vue_web == '2' ? true : false;

    /// COMBO POUR LA RECHERCHE (Option tec)
    // Destinataire
    const [destinataire, setDestinataire] = useState(querydestinataireRech ? querydestinataireRech : filtres && filtres.destinataire ? filtres.destinataire : account.person);
    const [inputdestinataire, setInputdestinataire] = useState('');
    const [tabdestinataire, setTabdestinataire] = useState([]);

    //  Société
    const [societe, setsociete] = useState(querysocieteRech ? account.societe.find(soc => soc.code.trim() == querysocieteRech.trim()).w_affich  : filtres && filtres.societe ?  account.societe.find(soc => soc.w_affich.trim() == filtres.societe.trim() ).w_affich : account.lib);
    const [inputsociete, setInputsociete] = useState('');
    const [tabsociete, setTabsociete] = useState(account.societe);
    console.log('querry', querysocieteRech);


    //  Clients
    const [clients, setClients] = useState(querydestinataireRech ? querydestinataireRech : filtres && filtres.destinataire ? filtres.destinataire : superUtilisateur ? '' : account.person);
    const [inputclients, setInputclients] = useState('');
    const [tabclients, setTabclients] = useState([]);

    //  Type appel
    const [typeAppel, settypeAppel] = useState(querytypeAppelRech ? querytypeAppelRech : filtres && filtres.typeAppel ? filtres.typeAppel : '');
    const [inputtypeAppel, setInputtypeAppel] = useState('');
    const [tabtypeAppel, setTabtypeAppel] = useState(['']);

    //  Produit
    const [produit, setProduit] = useState(queryproduitRech ? queryproduitRech : filtres && filtres.produit ? filtres.produit : '');
    const [inputproduit, setInputproduit] = useState('');
    const [tabproduit, settabproduit] = useState([]);

    /**************************** FONCTI0N SUBMIT ********************************/
    const handleSubmit = async (e = null) => {
        dispatch(setLoaderVisible());
        if (e) e.preventDefault();

        try {
            let tabCodeSociete = (tabsociete.map(_soc => _soc.code.trim())).join('-');
            let query = !ordre ? '' : 'ticketRech=' + ordre /*+ '&societeRech=' + (tabsociete.map(_soc => _soc.code.trim())).join('-') */ ;
            query += query == '' ? (!contains ? '' : 'contientRech=' + contains) : !contains ? '' : '&contientRech=' + contains;
            query += query == '' ? (closingCall == '' ? '' : 'clotRech=' + closingCall) : closingCall == '' ? '' : '&clotRech=' + closingCall;
            query += query == '' ? (typeTri == '' ? '' : 'tri=' + typeTri) : typeTri == '' ? '' : '&tri=' + typeTri;
            // Si aucune société séléctionné on fait la recherche sur toutes les sociétés de l'utilisateur
            query += query == '' ? (!societe ? 'societeRech=' + tabCodeSociete : 'societeRech=' + account.c_cli.trim()) : !societe ? '&societeRech=' + tabCodeSociete  : '&societeRech=' + tabsociete.find(soc => soc.w_affich.trim() == societe.trim() ).code.trim();
            //query += query == '' ? (!destinataire ? '' : 'demandeurRech=' + destinataire.trim()) : !destinataire.trim() ? '' : '&demandeurRech=' + destinataire.trim();

            if (superUtilisateur) {
                //query += query == '' ? (!clients ? '' : 'demandeurRech=' + tabclients.find((cli) => cli.w_affich == clients).code.trim()) : !clients.trim() ? '' : '&demandeurRech=' + tabclients.find((cli) => cli.w_affich == clients).code.trim();
                query += query == '' ? (!clients ? '' : 'demandeurRech=' + clients.trim()) : !clients ? '' : '&demandeurRech=' + clients.trim();
            } else {
                query += query == '' ? (!destinataire ? '' : 'demandeurRech=' + destinataire.trim()) : !destinataire.trim() ? '' : '&demandeurRech=' + destinataire.trim();
            }

            //query += query == '' ? (!typeAppel ? '' : 'typeAppelRech=' + typeAppel) : !typeAppel ? '' : '&typeAppelRech=' + typeAppel;
            query += query == '' ? (!typeAppel ? '' : 'typeAppelRech=' + tabtypeAppel.find((_type) => _type.w_affich == typeAppel).code.trim()) : !typeAppel ? '' : '&typeAppelRech=' + tabtypeAppel.find((_type) => _type.w_affich == typeAppel).code.trim();
            query += query == '' ? (!produit ? '' : 'produitRech=' + tabproduit.find((_produit) => _produit.lib_logiciel == produit).c_logiciel.trim()) : !produit ? '' : '&produitRech=' + tabproduit.find((_produit) => _produit.lib_logiciel == produit).c_logiciel.trim();
            query += query == '' ? (dateDeb == '' ? '' : 'dateRech=' + moment(dateDeb).format('YYYY/MM/DD')) : dateDeb == '' ? '' : '&dateRech=' + moment(dateDeb).format('YYYY/MM/DD');
            query += query == '' ? (dateFin == '' ? '' : 'dateFinRech=' + moment(dateFin).format('YYYY/MM/DD')) : dateFin == '' ? '' : '&dateFinRech=' + moment(dateFin).format('YYYY/MM/DD');

            query = query == '' ? '' : '?' + query.replaceAll(' ', '%20');
            
            console.log('querry tab soc', tabsociete);
            console.log('querry soc',societe);
            console.log('querry', query);

            //Saga demandes
            dispatch(demandeRequeste(account.code_acces.trim(), query));

            // on enregistre dans le store les filtres
            dispatch(demandeUpdateFiltres({ ordre, dateDeb, dateFin, closingCall, contains, typeTri, destinataire, societe, clients, typeAppel, produit }));
        } catch (error) {
            console.log('error handleSubmit', error);
        }

        dispatch(setLoaderHidden());
    };

    /**************************** USE EFFECT COMPOSANT INIT ********************************/
    useEffect(() => {
        dispatch(setLoaderVisible());

        (async () => {
            let produitSelect = produit;
            try {
                /******************************* On récupére les produits du client********************** */
                let url = `${API_GET_PRODUIT + (account.isTec ? '' : '?codeAcces=' + account.code_acces)}`;
                let reponse = await appelApiGet(url);
                settabproduit(reponse.data.reponse);
                console.log('produit', reponse.data.reponse[0].lib_logiciel);
                //setProduit(!produitSelect ? reponse.data.reponse[0].lib_logiciel : reponse.data.reponse.find((_produit) => _produit.lib_logiciel == produitSelect));
                /******************************* On récupére les type d'appels ********************** */

                reponse = await appelApiGet(API_GET_TYPE_APPEL);
                console.log('typeAppel', reponse.data.reponse);
                setTabtypeAppel(reponse.data.reponse);

                /******************************* On récupére clients si l'utilisateur et de type COMMUN ********************** */
                if (superUtilisateur) {
                    reponse = await appelApiGet(API_GET_CLIENT_SOCIETE(account.c_cli));
                    console.log('Liste client', reponse.data.reponse);
                    setTabclients(reponse.data.reponse.tabPersonne);
                }

                // On lance la recherche de demande avec les champs initialisé avec les valeurs par defaut
                if (!demandes) handleSubmit();

                if (account.isTec) {
                    /******************************* On récupére les technicien********************** */
                    reponse = await appelApiGetTechnicient(account.nom);
                    setTabdestinataire(reponse.data.reponse);
                }
            } catch (error) {
                console.log('error useeffect catch', error);
            }
        })();

        dispatch(setLoaderHidden());
    }, []);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={2}>
                                    <TextField fullWidth value={ordre} onChange={(e) => setOrdre(e.target.value)} label="Numéro d'ordre" type="number" size="small" variant="outlined"></TextField>
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <TextField fullWidth value={dateDeb} onChange={(e) => setDateDeb(e.target.value)} label="Du" type="date" size="small" variant="outlined" InputLabelProps={{ shrink: true }} ></TextField>
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <TextField fullWidth value={dateFin} onChange={(e) => setDateFin(e.target.value)} label="Au" type="date" size="small" variant="outlined" InputLabelProps={{ shrink: true }}></TextField> 
                                    
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <TextField fullWidth value={contains} onChange={(e) => setContains(e.target.value)} label="La demande contient" size="small" variant="outlined" ></TextField>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <FormControl component="fieldset">
                                        <RadioGroup row aria-label="closingCall" name="closingCall" value={closingCall} onChange={(event) => setClosingCall(event.target.value)}>
                                            <FormControlLabel value="1" control={<Radio />} label="En cours" />
                                            <FormControlLabel value="2" control={<Radio />} label="Clôturé" />
                                            <FormControlLabel value="0" control={<Radio />} label="Tous" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        {account.isTec && (
                                            <Grid item xs={12} sm={6}>
                                                <ComboApi
                                                    disabled={!account.isTec}
                                                    id="1"
                                                    size="small"
                                                    variant="outlined"
                                                    options={tabdestinataire.map((dest) => dest.w_affich)}
                                                    setOption={setTabdestinataire}
                                                    label="Destinataire"
                                                    value={destinataire}
                                                    setValue={setDestinataire}
                                                    inputValue={inputdestinataire}
                                                    setInputValue={setInputdestinataire}
                                                    funcApi={appelApiGetTechnicient}
                                                    useApi={true}
                                                    argumentsApi={[]}
                                                />
                                            </Grid>
                                        )}

                                        <Grid item xs={12} sm={6}>
                                            <ComboApi
                                                disabled={!superUtilisateur}
                                                id="2"
                                                size="small"
                                                variant="outlined"
                                                options={tabclients.map((cli) => cli.person)}
                                                setOption={setTabclients}
                                                label="Demandeur"
                                                value={clients}
                                                setValue={setClients}
                                                inputValue={inputclients}
                                                setInputValue={setInputclients}
                                                useApi={false}
                                                argumentsApi={[]}
                                                funcApi={appelApiGetClient}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <ComboApi
                                                disabled={account.societe.length > 1 ? false : true}
                                                id="3"
                                                size="small"
                                                variant="outlined"
                                                options={tabsociete.map((soc) => soc.w_affich)}
                                                setOption={setTabsociete}
                                                label="Société"
                                                value={societe}
                                                setValue={setsociete}
                                                inputValue={inputsociete}
                                                setInputValue={setInputsociete}
                                                useApi={false}
                                                argumentsApi={[]}
                                                funcApi={appelApiGetSociete}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <ComboApi
                                                id="5"
                                                size="small"
                                                variant="outlined"
                                                options={tabproduit.map((produit) => produit.lib_logiciel)}
                                                setOption={settabproduit}
                                                label="Produit"
                                                value={filtres && filtres.produit ? filtres.produit : produit}
                                                setValue={setProduit}
                                                inputValue={inputproduit}
                                                setInputValue={setInputproduit}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <ComboApi
                                                id="4"
                                                size="small"
                                                variant="outlined"
                                                setOption={setTabtypeAppel}
                                                options={tabtypeAppel.map((type) => type.w_affich)}
                                                label="Type demande"
                                                value={filtres && filtres.typeAppel ? filtres.typeAppel : typeAppel}
                                                setValue={settypeAppel}
                                                inputValue={inputtypeAppel}
                                                setInputValue={setInputtypeAppel}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <CardActions>
                            <Grid container spacing={2} className="setcion-recherche">
                                <Grid item xs={12} lg={6}>
                                    <FormLabel component="legend">Trier par</FormLabel>
                                    <RadioGroup row aria-label="radioTri" name="radioTri" defaultValue="1" value={typeTri} onChange={(event) => setTypeTri(event.target.value)}>
                                        <FormControlLabel value="1" control={<Radio />} label="Date de création décroissant" />
                                        <FormControlLabel value="2" control={<Radio />} label="Date de la dernière réponse" />
                                        <FormControlLabel value="3" control={<Radio />} label="Date de création croissant" />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12} lg={6} className="div-recherche">
                                    <Button startIcon={<SearchIcon />} onClick={(e) => handleSubmit(e)} variant="contained" color="primary">
                                        Rechercher
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default DemandesSearch;

const appelApiGetTechnicient = async (query = '') => {
    let reponse = await axios({
        method: 'get',
        url: query != '' ? API_GET_TEC_ALL + '?technicien=' + query : API_GET_TEC_ALL,
        //url: 'http://localhost:8080/tec?technicien=' + query,
        headers: {
            apiclef: API_KEY,
            authorization: 'Bearer ' + localStorage.getItem('token'),
        },
    });

    return reponse;
};

const appelApiGetClient = async (query = '') => {
    let reponse = await axios({
        method: 'get',
        url: query != '' ? API_GET_CLIENT_ALL + '?societe=' + query : API_GET_CLIENT_ALL,
        //url: 'http://localhost:8080/tec?technicien=' + query,
        headers: {
            apiclef: API_KEY,
            authorization: 'Bearer ' + localStorage.getItem('token'),
        },
    });

    return reponse;
};

const appelApiGetSociete = async (query = '') => {
    let reponse = await axios({
        method: 'get',
        url: query != '' ? API_GET_SOCIETE_ALL + '?societe=' + query : API_GET_SOCIETE_ALL,
        headers: {
            apiclef: API_KEY,
            authorization: 'Bearer ' + localStorage.getItem('token'),
        },
    });

    return reponse;
};
