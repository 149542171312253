import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import authService from '../../config/utils/authService';
import { Layout } from '../index';

const PrivateRoute = ({ component: Component, ...rest }) => {

    if (!authService.isAuthenticated) {
        // conservation des querystrings pour des actions spécifiques
        let action = '';
        let search = document.location.search;

        const pathname = document.location.pathname.toLowerCase();

        if (pathname.indexOf('request') > -1) {
            action = 'request';
        }

        if (search.length > 0 && action !== '') {
            search += '&action=' + action;
            localStorage.setItem('forceRedirect', search);
        }
    }

    return (
        <Route
            {...rest}
            render={props =>
                authService.isAuthenticated ? (
                    <Layout {...props} Component={Component} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
}

export default PrivateRoute;
