import { Button, Grid, Link, Tooltip, Typography, RadioGroup, FormControlLabel, FormControl, Radio } from '@mui/material';
import React, { useEffect } from 'react';
import NewDemande from '../../assets/images/png/newDemande.png';
import Formation from '../../assets/images/png/catalogue.png';
import Actualites from '../../assets/images/png/actualite.png';
import Inscrire from '../../assets/images/png/inscrire.png';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import moment from 'moment';
import { API_GET_PRODUIT, API_GET_TOTAL_DEMANDE, API_GET_VARGEN } from '../../config/config';
import { useDispatch, useSelector } from 'react-redux';
import { setLoaderHidden, setLoaderVisible } from '../../redux/actions/actionsApp';
import { appelApiGet } from '../../config/utils/apiFunction';
import { demandeUpdateFiltres } from '../../redux/actions/actionDemande';
//import NewDemande from '../../assets/images/svg/request.svg';

const Home = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    /********************************* Données récupérés du store************************************************************** */
    const account = useSelector((state) => state.modAuth.account);
    /********************************* Etat local composant ************************************************************** */
    const [enCours, setEnCours] = useState(0);
    const [enAttente, setEnAttente] = useState(0);
    const [queryDemande, setqueryDemande] = useState('');
    const [queryMail, setqueryMail] = useState('');
    const [tabproduit, settabproduit] = useState([]);
    const [tabproduitBTNRadio, settabproduitBTNRadio] = useState([]);
    const [closingCall, setClosingCall] = useState(0);
    const [indiceWidget, setindiceWidget] = useState(0);
    
    const [lien_inter_lo, set_lien_inter_lo] = useState('');
    const [lien_inter_ho, set_lien_inter_ho] = useState('');
    const [lien_inter_next, set_lien_inter_next] = useState('');


    const afficheWidget= (index) => {
        console.log('value ', index);
        setClosingCall(index)
        setindiceWidget(index )
    }

    const changeLiens = () => {
        if(indiceWidget == tabproduitBTNRadio.length -1 ){ 
            setindiceWidget(0)
        }else {
            setindiceWidget(indiceWidget +1 )
        }
    }



    /********************************* Use effect init composant widget demandes  ************************************************************** */
    useEffect(() => {
        (async () => {
            dispatch(setLoaderVisible());
            try {
                // On enregistre les filtres dans le store pour lancer la recherche si l'utilisateur clique sur le widjet
                let dateDebut = moment().startOf('month').format('YYYY-MM-DD')
                let dateFinRech = moment().format('YYYY-MM-DD');
                //setqueryDemande(`?clotRech=1&tri=1&societeRech=${account.c_cli.trim()}&dateRech=${dateDebut}&dateFinRech=${dateFinRech}&demandeurRech=${account.person.trim()}`)
                setqueryDemande(`?clotRech=1&tri=1&societeRech=${account.c_cli.trim()}&demandeurRech=${account.person.trim()}`)
                setqueryMail(`?tri=1&dateRech=${dateDebut}&dateFinRech=${dateFinRech}`)

                
                
                // Format du champs date pour la recherche des date different de celui dans la bdd on le remet au format du champ
                //  dateDebut = moment().startOf('month').format('YYYY-MM-DD')
                // dateFinRech = moment().format('YYYY-MM-DD')
                // Requete demandé pour le mois en cours 
                const query = `?societeRech=${account.c_cli.trim()}`
                const reponse = await appelApiGet(API_GET_TOTAL_DEMANDE(account.mail.trim() , account.person.trim(), query) );
                console.log('reponse api ', reponse.data.reponse[0]);
                setEnCours(reponse.data.reponse[0].totAppelEnCours ? reponse.data.reponse[0].totAppelEnCours : 0);
                setEnAttente(reponse.data.reponse[0].totAppelAttente ? reponse.data.reponse[0].totAppelAttente : 0);
            } catch (error) {
                console.log('erreur catch', error);
                dispatch(setLoaderHidden());
            }
            dispatch(setLoaderHidden());
        })();
    }, []);

        /**************************** USE EFFECT GET PRODUIT ET VAR_GEN LIENS ********************************/
        useEffect(() => {
            dispatch(setLoaderVisible());
    
            (async () => {
                try {
                    /******************************* On récupére les produits du client ********************** */
                    let url = `${API_GET_PRODUIT + ('?codeAcces=' + account.code_acces)}`;
                    let reponse = await appelApiGet(url);
                    settabproduit(reponse.data.reponse);
                    console.log('settabproduit', reponse.data.reponse);
                    
                    // On récupère les liens de formation
                    const tabtempo = [] // pour ajouter dans le state des formations en une seule fois
                    let bHomereExiste = false // Si Le produit homere existe pas besoin d'afficher les formations Next
                    for(const _produit of reponse.data.reponse ){
                        //Leon
                        if (_produit.c_logiciel.trim() == 'LO') {
                            url = `${API_GET_VARGEN('lien_inter_lo')}`;
                            reponse = await appelApiGet(url);
                            console.log('lien inter lo', reponse.data.reponse[0].comment);
                            set_lien_inter_lo(reponse.data.reponse[0].comment)

                            tabtempo.push({..._produit, lien: reponse.data.reponse[0].comment.trim()})
                            
                            continue
                        }

                        //Homere
                        else if (_produit.c_logiciel.trim() == 'OB') {
                            url = `${API_GET_VARGEN('lien_inter_ho')}`;
                            reponse = await appelApiGet(url);
                            console.log('lien inter ho', reponse.data.reponse[0].comment);
                            set_lien_inter_ho(reponse.data.reponse[0].comment)

                            tabtempo.push({..._produit, lien: reponse.data.reponse[0].comment.trim()})
                            bHomereExiste = true 
                            continue
                        }
                        //Next
                        else if (_produit.c_logiciel.trim() == 'NXA') {
                            console.log('bHomereExiste', bHomereExiste);
                            if(bHomereExiste) continue
                            url = `${API_GET_VARGEN('lien_inter_next')}`;
                            reponse = await appelApiGet(url);
                            console.log('lien inter next', reponse.data.reponse[0].comment);
                            set_lien_inter_next(reponse.data.reponse[0].comment)
                            tabtempo.push({..._produit, lien: reponse.data.reponse[0].comment.trim()})
                            continue
                        }    
                    }
                    console.log('tabtempo', tabtempo);
                    settabproduitBTNRadio(tabtempo)

                } catch (error) {
                    console.log('error useeffect catch', error);
                }
            })();
    
            dispatch(setLoaderHidden());
           
        }, []);


        useEffect(() => {
            console.log('tabproduitBTNRadio', tabproduitBTNRadio);
        }, [tabproduitBTNRadio]);

    //
    return (
        <div>
            <h1>Accueil</h1>
            <Grid container spacing={2}>
                {/**************************************** WIDGET DEMANDES *********************************************************** */}
                <Grid item xs={12} lg={4} className="widget-Demande">
                    <Tooltip title={'Faire une demande au support de Scepia'} placement="bottom">
                        <div className="card click" onClick={(e) => history.push('/nouvelleDemande/0/0/0/0')}>
                            <img className="image" src={NewDemande}></img>

                            <p>Déclarer une nouvelle demande</p>
                        </div>
                    </Tooltip>
                    <div className="container-widget " >
                        <Tooltip title={'Total des demandes qui sont en cours de traitement par le support '} placement="bottom">
                            <div className="card widget " onClick={(e) => history.push('/demandes' + queryDemande)}>
                                <p className="valeur en-cours">{enCours}</p>
                                <p> Demandes en cours</p>
                            </div>
                        </Tooltip>
                        <Tooltip title={'Total des demandes non traitées par le support'} placement="bottom">
                            <div className="card widget " onClick={(e) => history.push('/mails' + queryMail)}>
                                <p className="valeur en-attente" >{enAttente}</p>
                                <p> Demandes non traitées</p>
                            </div>
                        </Tooltip>

                    </div>

                </Grid>




                {/****************************************  Wigdet Catalogue de formations  *********************************************************** */}
                <Grid item xs={12} lg={4} className="widget-Demande ">

                    <Tooltip title={'Catalogue de formation Scepia '} placement="bottom">
                        <Link className="card click catalogue" href='https://www.scepia.fr/services/demande-de-catalogue-de-formation/' target={"_blank"}>
                            <img className="image" src={Formation}></img>
                            <p>
                                Catalogue de formation Scepia
                            </p>
                        </Link>
                    </Tooltip>
                    <div className="container-widget " >
                    {/****************************************  Wigdet Dernières actualités  *********************************************************** */}
                        <Tooltip title={'Dernières actualités'} placement="bottom">
                            <Link className="card click catalogue" href='https://www.scepia.fr/actualites/' target={"_blank"}>
                                <img className="image" src={Actualites}></img>
                                <p>
                                    Dernières actualités
                                </p>
                            </Link>
                        </Tooltip>



                        {/* Si l'utilisateur a plus d'un logiciel proposant une formation on n'affiche  des bouton radio pour afficher le bon widget */}
                        { /*tabproduitBTNRadio.length > 1 && (

                            <div className='widget-formation'>

                                <Tooltip title={'Catalogue de formation Scepia ' + tabproduitBTNRadio[indiceWidget].lib_logiciel.trim()} placement="bottom">
                                    <Link className="card click catalogue lien-formation" href={tabproduitBTNRadio[indiceWidget].lien} target={"_blank"}>{'S\'inscrire à une formation inter ' + tabproduitBTNRadio[indiceWidget].lib_logiciel.trim()}</Link>
                                </Tooltip>
                                {
                                
                                <FormControl component="fieldset">
                                <RadioGroup row aria-label="closingCall" name="closingCall" value={closingCall} onChange={(event) => afficheWidget(event.target.value)}>
                                    {tabproduitBTNRadio.map((produit, index) => {

                                        return (
                                            <FormControlLabel value={index} control={<Radio />} label={produit.lib_logiciel} />
                                        );
                                    })}
                                </RadioGroup>
                            </FormControl>

                                }

                            </div>
                            ) */} 


                    </div>

                </Grid>


                
                {/****************************************  Wigdet Fomration Inter *********************************************************** */}
                <Grid item xs={12} lg={4} className="widget-Demande ">

                    {/* Si l'utilisateur n'a qu'un logiciel proposant une formation on n'affiche le widget*/}
                    {(tabproduitBTNRadio.length <= 2  && tabproduitBTNRadio.length > 0 )  && (
                        <Tooltip title={'formation inter Scepia ' + tabproduitBTNRadio[0].lib_logiciel.trim()} placement="bottom">
                            
                            <Link className="card click catalogue" href={tabproduitBTNRadio[0].lien} target={"_blank"}>
                                <img className="image" src={Inscrire}></img>
                                <p>
                                    {'S\'inscrire à une formation inter ' + tabproduitBTNRadio[0].lib_logiciel.trim()}
                                </p>
                            </Link>
                        </Tooltip>
                    )}
                    {tabproduitBTNRadio.length == 2 && (
                        <div className="container-widget " >
                             <Tooltip title={'formation inter Scepia ' + tabproduitBTNRadio[1].lib_logiciel.trim()} placement="bottom">
                            
                                
                                <Link className="card click catalogue" href={tabproduitBTNRadio[1].lien} target={"_blank"}>
                                    <img className="image" src={Inscrire}></img>
                                    <p>
                                        {'S\'inscrire à une formation inter ' + tabproduitBTNRadio[1].lib_logiciel.trim()}
                                    </p>
                                </Link>
                            
                        </Tooltip>
                        </div>
                    )}
                </Grid>
                
            </Grid>
            <p className='source_icone' >Source icones : <Link href={'https://icones8.fr/'} target={"_blank"}>icones8</Link></p>
        </div>
    );
};

export default Home;


