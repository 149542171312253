/**
 * REDUCER pour la gestion de l'authentification de l'utilisateur
 */
import * as types from '../../actions/actionTypes/mailsAction';

export const initialState = {
    loading: false,
    success: null,
    error: null,
    mails: null,
    pagination: 1,
    filtresRecherche: null,
};

export const mails = (state = initialState, action) => {
    console.log('state en cours ', state);
    switch (action.type) {
        case types.MAIL_REQUEST:
            return { ...state, error: null, success: null, loading: true };

        case types.MAIL_SUCCESS:
            return {
                ...state,
                mails: action.mails,
                success: true,
                loading: false,
                success: true,
            };

        case types.MAIL_ADD:
            return {
                ...state,
                mails: !state.mails ? [action.newMail] : [action.newMail, ...state.mails],
                success: true,
                loading: false,
                success: true,
                pagination: 1,
            };

        case types.MAIL_ERROR:
            return {
                ...state,
                mails: null,
                error: action.error,
                loading: false,
            };
        case types.MAIL_UPDATE_PAGINATION:
            return {
                ...state,
                pagination: action.pagination,
            };
        case types.MAIL_UPDATE_FILTRE:
            return {
                ...state,
                filtresRecherche: action.filtres,
            };

        case types.CLEAR_MESSAGES:
            return { ...initialState };

        default:
            return state;
    }
};

export default mails;
