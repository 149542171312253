import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { API_KEY, API_CHECK_LOGIN } from '../../../config/config';
import authService from '../../../config/utils/authService';
import { loginSuccess } from '../../../redux/actions/actionsAuth';
import { push } from 'connected-react-router';
import * as types from '../../actions/actionTypes/auth';

const login = (utilisateur, motdepasse) => {
    return axios({
        method: 'get',
        url: `${API_CHECK_LOGIN}`,
        headers: {
            apiclef: API_KEY,
            utilisateur,
            motdepasse,
        },
    });
};

export function* workerLogin(action) {
    try {
        const response = yield call(login, action.username, action.password);

        if (response.data.ok) {
            const account = response.data.reponse.account;

            // Lecture du token
            const token = response.data.reponse.jeton;
            yield call(authService.addToken, token);

            // IMPORTANT :  permet d'initialiser les data après refresh
            localStorage.setItem('account', JSON.stringify(account));

            yield put(loginSuccess(account, token));
            // yield call(push, 'home');
        } else {
            console.log('response.data', response.data);
            yield put({
                type: types.LOGIN_ERROR,
                error: 'Identifiant ou mot de passe incorrect', //response.data.erreur_message,
            });
        }
    } catch (error) {
        console.log('ERROR catch login', error);
        yield put({
            type: types.LOGIN_ERROR,
            error: 'Erreur lors de la connexion au serveur',
        });
    }
}

export function* workerGetAccount(action) {
    try {
        // TODO

        // const response = yield call(getAccount, token);

        // const account = response.data;

        //!!!IMPORTANT - Réinitialisation des data globales à l'application à partir du local storage afin d'éviter de refaire des appels serveur

        const account = JSON.parse(localStorage.getItem('account'));

        yield put({ type: types.GET_ACCOUNT_SUCCESS, account });

        //yield put(setRequestTypesFull());

        // const modRequest = JSON.parse(localStorage.getItem('modRequest'));

        // yield put(initModRequest(modRequest));

        // const modUser = JSON.parse(localStorage.getItem('modUser'));

        // yield put(initModUser(modUser));

        // const app = JSON.parse(localStorage.getItem('app'));

        // yield put(setGlobalParams(app));
    } catch (error) {
        yield put({ type: types.GET_ACCOUNT_ERROR, error });
    }
}

export function* watcherAuth() {
    yield takeLatest(types.LOGIN_REQUEST, workerLogin);
    yield takeLatest(types.GET_ACCOUNT_REQUEST, workerGetAccount);
}
