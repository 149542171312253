import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PrivateRoute } from './components';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { history } from './redux/store';
import { initModAuth } from './redux/actions/actionsAuth';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Import des pages
import Login from './pages/Login/index.jsx';
import Home from './pages/Home';
import Demandes from './pages/Demandes/demandes';

import Logout from './pages/Logout';
import Error404 from './pages/Error404';
import DemandeDetail from './pages/Demandes/component/demandes/demandeDetail';
import DemandeNew from './pages/Demandes/component/demandes/demandeNew';
import VideoFormation from './pages/Video/video';
import FormMajInfoPerso from './pages/InfoPerso/majPerso';
import Telechargement from './pages/Telechargement';
import MailsEnAttente from './pages/Demandes/component/demandesAttente/mails';
import MailDetail from './pages/Demandes/component/demandesAttente/mailDetail';
import GestionContact from './pages/GestionContact/gestionContact';

const App = () => {
    const dispatch = useDispatch();

    // Intialisation des modules si refresh de la page
    // en lisant le localstorage
    useEffect(() => {
        AOS.init({ duration: 1000 }); // Initialisation animation AOS pour apparition continue sur scroll
        // dispatch(initModAuth());
    }, []);

    if (process.env.REACT_APP_ENV === 'PROD') console.log = function no_console() {};
    //
    return (
        <ConnectedRouter history={history}>
            <Switch>
                <Route exact path="/" component={Login} />
                <Route path="/monespace" component={Login} />
                <PrivateRoute path="/home" component={Home} />
                <PrivateRoute path="/demandes" component={Demandes} />
                <PrivateRoute path="/mails" component={MailsEnAttente} />
                <PrivateRoute path="/detailDemande/:idUser/:ordre" component={DemandeDetail} />
                <PrivateRoute path="/detailDemandeAttente/:idUser/:id" component={MailDetail} />
                <PrivateRoute path="/nouvelleDemande/:type/:nom/:prenom/:cause" component={DemandeNew} />
                <PrivateRoute path="/video" component={VideoFormation} />
                <PrivateRoute path="/formChangePassword" component={FormMajInfoPerso} />
                <PrivateRoute path="/telechargement" component={Telechargement} />
                <PrivateRoute path="/gestionContact" component={GestionContact} />

                <PrivateRoute path="/logout" component={Logout} />
                <Route component={Error404} />
            </Switch>
        </ConnectedRouter>
    );
};

export default App;
