/// REACT & REDUX
import React, { useState } from 'react';

/// Composant moment
import moment from 'moment';

/// Composant material UI
import { Grid, Button, Typography, Card, CardContent, CardActions } from '@mui/material';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const MailItem = ({ item, userConnect, sizeMaxContent = 120, onItemSelect }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [bVoirPlus, setbVoirPlus] = useState(false)

    let tab_split_date = moment(item.d_reception, 'YYYY/MM/DD HH:mm').format('DD/MM/YYYY HH:mm').split(' ');
    const date_demande = tab_split_date[0] + ' à ' + tab_split_date[1];

    return (
        <Grid container spacing={1} className="ticket">
            <Grid item xs={12}>
                <Card onMouseOver={()=> setbVoirPlus(true)} onMouseOut={()=> setbVoirPlus(false)}  className="HERMESS_CardDemandes" onClick={(e) => history.push(`/detailDemandeAttente/${userConnect.person.trim()}/${item.id}`)}>
                    <CardContent className="">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container wrap="nowrap">
                                            <Grid item xs={12}>
                                                {' '}
                                                <Typography color="#457b9d" fontWeight={700} component="span" variant="subtitle1">{` ${item.objet.length > 50 ? item.objet.substr(0, 50) + ' ...' : item.objet} `}</Typography>
                                                <hr />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="span" variant="subtitle2" color="textSecondary" gutterBottom>
                                            Demande du  {date_demande}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="echange" component="p">
                                    {item.corps.substr(0, sizeMaxContent)}...
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid container justifyContent="flex-end" className="bottomCard">
                            <Grid item>
                                {bVoirPlus && <Typography className='voirPlus'  color={'primary'}>VOIR PLUS</Typography>}
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    );
};

export default MailItem;
