import * as types from "../../redux/actions/actionTypes/auth";

const authService = {

    isAuthenticated: false,

    addToken(token, tokenType, expiresAt) {
        localStorage.setItem('token', token);
        authService.isAuthenticated = true;
    },
    removeToken() {
        localStorage.removeItem('token');
        authService.isAuthenticated = false;
    },
    getToken() {
        return localStorage.getItem('token')
    },
    tokenLogin(store) {
        if (localStorage.getItem('token')) {
            authService.isAuthenticated = true;
            store.dispatch({ type: types.TOKEN_LOGIN, token: localStorage.getItem('token') });
            store.dispatch({ type: types.GET_ACCOUNT_REQUEST });
        }
    },
    clearData() {
        this.removeToken();
        localStorage.clear()
        // localStorage.removeItem('account');
        //Définition des élements du local storage à supprimer
        // ex: localStorage.removeItem('...');
    }
};

export default authService;