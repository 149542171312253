import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App.jsx';
import './assets/scss/index.scss';
import authService from './config/utils/authService';
import configureStore from './redux/store';

// Déclaration du store global à toute l'application
const store = configureStore();

// !!!IMPORTANT : Lecture du token s'il existe dans le locale storage pour passer l'authentification
authService.tokenLogin(store);

ReactDOM.render(
	<Provider store={store}>
		<React.StrictMode>
			<App />
		</React.StrictMode>
	</Provider>,
	document.getElementById('root')
);
