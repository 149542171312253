/**
 * REDUCER pour la gestion de l'authentification de l'utilisateur
 */
import * as types from '../../actions/actionTypes/demandes';

export const initialState = {
    loading: false,
    success: null,
    error: null,
    demandes: null,
    pagination: 1,
    filtresRecherche: null,
};

export const demandes = (state = initialState, action) => {
    switch (action.type) {
        case types.DEMANDE_REQUEST:
            return { ...state, error: null, success: null, loading: true };

        case types.DEMANDE_SUCCESS:
            return {
                ...state,
                demandes: action.demandes,
                success: true,
                loading: false,
                pagination: 1,
            };

        case types.DEMANDE_ERROR:
            return {
                ...state,
                demandes: null,
                error: action.error,
                loading: false,
            };
        case types.DEMANDE_MODIF_VU:
            return {
                ...state,
                demandes: state.demandes.map((demande) => {
                    if (demande.ordre == action.ordre) {
                        return { ...demande, vuPar: `(${action.person})` };
                    }
                    return demande;
                }),
            };
        case types.DEMANDE_UPDATE_PAGINATION:
            return {
                ...state,
                pagination: action.pagination,
            };
        case types.DEMANDE_UPDATE_FILTRE:
            return {
                ...state,
                filtresRecherche: action.filtres,
            };

        case types.CLEAR_MESSAGES:
            return { ...initialState };

        default:
            return state;
    }
};

export default demandes;
