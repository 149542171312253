/**
 * REDUCER pour la gestion de l'authentification de l'utilisateur
 */
import * as types from '../../actions/actionTypes/auth';

export const initialState = {
    loading: false,
    token: '',
    success: null,
    error: null,
    account: null,
};

export const modAuth = (state = initialState, action) => {
    switch (action.type) {
        // Gestion de l'authentification
        case types.LOGIN_REQUEST:
            return { ...state, error: null, success: null, loading: true };

        case types.LOGIN_SUCCESS:
            return { ...state, token: action.token, account: action.account, success: true, loading: false };

        case types.LOGIN_ERROR:
            return { ...state, token: '', account: null, error: action.error, loading: false };

        case types.GET_ACCOUNT_SUCCESS:
            return { ...state, account: action.account };

        case types.CLEAR_MESSAGES:
            return { ...state, error: null, success: null, token: '' };

        case types.DECONNECT:
            return { ...state, account: null };

        case types.INIT_MOD_AUTH:
            return { ...state, account: JSON.parse(localStorage.getItem('account')), token: localStorage.getItem('token') };
        //return { ...state, account: JSON.parse(localStorage.getItem("account")), token: localStorage.getItem("token") };

        default:
            return state;
    }
};

export default modAuth;
