/// REACT & REDUX
import React, { useEffect, useState } from 'react';

/// Composant material UI
import { Grid, Button, TextField, Card, CardContent, CardActions, Typography } from '@mui/material';
import {  Add as AddIcon } from '@mui/icons-material';
import { useParams } from 'react-router';
import { API_GET_CLIENT_SOCIETE, API_GET_PRODUIT, API_GET_PRODUIT_LOCALISATION, API_GET_TYPE_APPEL, API_KEY, API_POST_DEMANDE } from '../../../../config/config';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setLoaderHidden, setLoaderVisible } from '../../../../redux/actions/actionsApp';
import { FilePond, registerPlugin } from 'react-filepond'; // File
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import ComboApi from '../comboApi';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { appelApiGet } from '../../../../config/utils/apiFunction';
import { mailAdd } from '../../../../redux/actions/actionMails';
import { useHistory } from 'react-router-dom';

toast.configure();

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType);

const DemandeNew = () => {
    const dispatch = useDispatch();
    const history  = useHistory();
    const paramsUrl = useParams();

    /** Selection  */
    const account = useSelector((state) => state.modAuth.account);

    /** Use state  Formulaire*/
    const [listeProduitsClient, setListeProduitsClient] = useState([]);
    const [listeLocalisationProduit, setListeLocalisationProduit] = useState([]);
    const [listeSocieteClient, setListeSocieteClient] = useState([]);
    const [listeTypesAppels, setListeTypesAppels] = useState([]);
    const [errForm, seterrForm] = useState({ input: '', erreur: '' });

    //  Produit
    const [produit, setProduit] = useState('');
    const [inputproduit, setInputproduit] = useState('');
    const [tabproduit, settabproduit] = useState([]);

    //  Module
    const [module, setmodule] = useState('');
    const [inputmodule, setInputmodule] = useState('');
    const [tabmodule, settabmodule] = useState([]);

    // //  traitement
    // const [traitement, settraitement] = useState('');
    // const [inputtraitement, setInputtraitement] = useState('');
    // const [tabmodule, settabmodule] = useState([]);

    //  Employee société
    const [employe, setempploye] = useState('');
    const [inputemploye, setInputemploye] = useState('');
    const [tabemploye, settabemploye] = useState([]);

    //  Type appel
    const [typeAppel, settypeAppel] = useState('');
    const [inputtypeAppel, setInputtypeAppel] = useState('');
    const [tabtypeAppel, setTabtypeAppel] = useState(['']);

    //  Société
    const [societe, setsociete] = useState(account.lib);
    const [inputsociete, setInputsociete] = useState('');
    const [tabsociete, setTabsociete] = useState(account.societe);

    const [localisation, setLocalisation] = useState();

    const [demandeur, setDemandeur] = useState();
    //const [typeAppel, setTypeAppel] = useState();

    const [commentaire, setCommentaire] = useState();
    const [objet, setObjet] = useState('');
    const [champMailEmployee, setchampMailEmployee] = useState('');
    const [champTelEmployee, setchampTelEmployee] = useState('');
    const [form_filePond, setForm_filePond] = useState([]);
    const [autreReferant, setautreReferant] = useState(false);

    /**************************** USE EFFECT COMPOSANT INIT ********************************/
    useEffect(() => {
        dispatch(setLoaderVisible());

        (async () => {
            try {
                /******************************* On récupére les produits du client********************** */
                let url = `${API_GET_PRODUIT + (account.isTec ? '' : '?codeAcces=' + account.code_acces)}`;
                let reponse = await appelApiGet(url);
                settabproduit(reponse.data.reponse);
                console.log('produit', reponse.data);
                setProduit(reponse.data.reponse[0].lib_logiciel);

                /******************************* On récupére les type d'appels ********************** */

                reponse = await appelApiGet(API_GET_TYPE_APPEL);
                if (reponse.data.existe) setTabtypeAppel(reponse.data.reponse);
                console.log('type appel', reponse.data);
                
                // On affiche les paramètre par defaut si les params url existe 
                if(paramsUrl.type === "0" && !reponse.data.existe ) return; 
                if (paramsUrl.type === "L") settypeAppel(reponse.data.reponse[0].w_affich.trim());
                // On maj l'objet de la demande
                setObjet("Modification du contact " + paramsUrl.nom + " " + paramsUrl.prenom);
                setCommentaire(paramsUrl.cause);
            } catch (error) {
                console.log('error useeffect catch', error);
            }
        })();

        dispatch(setLoaderHidden());
    }, []);


    useEffect(() => {

        if (!inputproduit) setInputproduit('')
        if (!inputtypeAppel) settypeAppel('')
        if (!commentaire) setCommentaire('')
        if (!champMailEmployee) setchampTelEmployee('')

        
        seterrForm({ input: '', erreur: '' });
    }, [inputproduit, inputtypeAppel, commentaire, champMailEmployee]);



    /**************************** USE EFFECT autre Referant   ********************************/
    useEffect(() => {
        // si la demande est pour la personne qui est connecté on ne fait rien
        if (! autreReferant) return;
        // On récupère la liste des employés de la société de l'utilisateur connecté
        (async () => {
            try {
                //const reponse = await appelApiGet(API_GET_CLIENT_SOCIETE(account.c_cli.trim()));
                const reponse = await appelApiGet(API_GET_CLIENT_SOCIETE(tabsociete.find((soc) => soc.w_affich.trim() == societe.trim()).code));
                if (!reponse.data.ok) {
                    console.log('impossible de récupérer les employés de la société');
                    return;
                }

                settabemploye(reponse.data.reponse);
            } catch (error) {
                console.log('error cath get employé', error);
            }
        })();
    }, [autreReferant, societe]);

    //Chaque foi qu'un enploye est séléctionné on met à jour le champs mail et tel
    useEffect(() => {
        if (!tabemploye.length > 0) return;
        if (!employe) return;

        let myemploye = tabemploye.find((_employe) => _employe.person.trim() == employe.trim());

        setchampMailEmployee(myemploye.mail.trim());
        setchampTelEmployee(myemploye.tel.trim());
    }, [employe]);


        //Chaque fois que le produit change on maj le module traitement
        useEffect(() => {
           
            if (!produit) return;
    
            (async () => {
                try {
                    /******************************* On récupére les produits du client********************** */
                    let url = `${API_GET_PRODUIT_LOCALISATION(tabproduit.find((_produuit) => _produuit.lib_logiciel.trim() == produit.trim()).c_logiciel)}`;
                    let reponse = await appelApiGet(url);
                    settabmodule(reponse.data.reponse);
                    console.log('module', reponse.data.reponse);

    
                } catch (error) {
                    console.log('error useeffect catch', error);
                }
            })();
        }, [produit]);

        // useEffect(()=> {
        //     //Rien dans les parmas ? on ne fait rien
        //     console.log("paramsUrl", paramsUrl);
        //     if(paramsUrl.type === "0" ||  tabtypeAppel.length < 2) return; 

        //     //On filtre le type appel de la demande sur celui placé dans l'url
        //     const tAppelFromUrl = tabtypeAppel.filter(ta => ta.code == paramsUrl.type) ;
        //     console.log("tAppelFromUrl", tAppelFromUrl);
        //     settypeAppel(tAppelFromUrl[0].w_affich.trim());
        //     // On maj l'objet de la demande
        //     setObjet("Modification du contact " + paramsUrl.nom + " " + paramsUrl.prenom);
        //     setCommentaire(paramsUrl.cause);

        // }, [paramsUrl, tabtypeAppel])
        useEffect(() => {
            console.log('typeAppel', typeAppel);
        }, [typeAppel]);

    /**************************** Submit Form ********************************/
    const handleSendForm = async (e) => {
        e.preventDefault();
        dispatch(setLoaderVisible());
        console.log('tabproduit', tabproduit);

        try {
            /** Vérification des informations du formulaire */
            if (!commentaire) {
                seterrForm({ input: 'commentaire', erreur: 'Votre demande est vide' });
                console.error('pas de commentaire');
                dispatch(setLoaderHidden());
                return;
            }
            if (!produit) {
                seterrForm({ input: 'produit', erreur: 'Veuillez séléctionner un produit' });
                console.error('pas de produit');
                dispatch(setLoaderHidden());
                return;
            }
            if (!typeAppel) {
                seterrForm({ input: 'typeAppel', erreur: 'Veuillez séléctionner le motif de la demande' });
                console.error('pas de typeAppel');
                dispatch(setLoaderHidden());
                return;
            }

            if (!module) {
                seterrForm({ input: 'module', erreur: 'Veuillez séléctionner le module' });
                console.error('pas de module');
                dispatch(setLoaderHidden());
                return;
            }
            let autreInformation = '' ; 
            if (autreReferant ) {
                if(!champMailEmployee){
                    seterrForm({ input: 'champMailEmployee', erreur: 'Veuillez rensiegner le mail de la personne à qui se référer' });
                    dispatch(setLoaderHidden());
                    return;
                }

                autreInformation = ` \r Pour plus d'information se référer à ${employe} par mail: ${champMailEmployee}${champTelEmployee ? ' ou par téléphone: ' + champTelEmployee : '.' } 
                `
            }

            const body = {
                question: commentaire + autreInformation  ,
                fichiers: form_filePond && form_filePond.length ? form_filePond.map((file) => ({ bufferFic: file.getFileEncodeBase64String(), nom: file.filename })) : [],
                expediteur:  account.person.trim() ,
                mail:  account.mail.trim() ,
                tel:  account.tel.trim() ,
                module: tabmodule.find( (t) => t.w_affich.trim()  == module.trim() ).code,
                //traitement: traitement,
                t_appel: tabtypeAppel.find( (ta) => ta.w_affich.trim()  == typeAppel.trim() ).code,
                c_cli: tabsociete.find((soc) => soc.w_affich.trim() == societe.trim()).code,
                c_logiciel: tabproduit.find((_produuit) => _produuit.lib_logiciel.trim() == produit.trim()).c_logiciel,
                objet: (typeAppel ? typeAppel.trim() + ' - ' : '')  + (module ? module.trim() + ' - ' : '')  + objet,
                person: account.person,

            };
            console.log('body', body);
           const posttDemande = await axios({
                method: 'POST',
                url: API_POST_DEMANDE(account.code_acces.trim()),
                headers: {
                    apiclef: API_KEY,
                    authorization: 'Bearer ' + localStorage.getItem('token'),
                },
                data: body,
            });
            console.log('retour PUT demande', posttDemande.data);
            if (!posttDemande.data.ok) {
                console.log('retour PUT demande error');
                toast.error("Problème lors de l'ajout du ticket ", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000,
                });
            } else {
               
                setCommentaire('');
                setForm_filePond();
                toast.success('Votre ticket a été ajoutée avec succès ', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000,
                });
                 // Maj des info du ticket sans actualiser
                const newDemande = {
                    id: posttDemande.data.reponse.id,
                    expediteur: posttDemande.data.reponse.expediteur,
                    objet: posttDemande.data.reponse.objet,
                    d_reception: posttDemande.data.reponse.d_reception,
                    corps: posttDemande.data.reponse.corps,
                    person: posttDemande.data.reponse.person,
                };
                dispatch(mailAdd(newDemande));
                history.push('/mails')
            } 
        } catch (error) {
            console.error('error', error);
            toast.error('Error lors de la création de la demande', {
                position: toast.POSITION.BOTTOM_RIGHT,
                type: toast.TYPE.ERROR,
                autoClose: 5000,
            });
        }
        dispatch(setLoaderHidden());
    };

    // const handleResetForm = (e) => {
    //     e.preventDefault();
    //     setCommentaire('');
    //     setForm_filePond('');
    //     setautreReferant(false);
    //     setObjet('');
    // };

    return (
        <>
            <Grid container spacing={1}>
                {/* titre de page */}
                <Grid item xs={12}>
                    <h1>Nouvelle demande</h1>
                </Grid>
                {/** Formulaire  */}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <ComboApi id="4" required size="small" variant="outlined" options={tabtypeAppel.map((type) => type.w_affich)} label="Type de demande" value={typeAppel} setValue={settypeAppel} inputValue={inputtypeAppel} setInputValue={setInputtypeAppel} />
                                            {errForm.input == 'typeAppel' && <Typography color="red">{errForm.erreur}</Typography>}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <ComboApi
                                                id="5"
                                                size="small"
                                                variant="outlined"
                                                options={tabproduit.map((produit) => produit.lib_logiciel)}
                                                setOption={settabproduit}
                                                label="Produit"
                                                value={produit}
                                                setValue={setProduit}
                                                inputValue={inputproduit}
                                                setInputValue={setInputproduit}
                                                required
                                            />
                                            {errForm.input == 'produit' && <Typography color="red">{errForm.erreur}</Typography>}
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <ComboApi
                                                disabled={account.societe.length > 1 ? false : true}
                                                id="3"
                                                size="small"
                                                variant="outlined"
                                                options={tabsociete.map((soc) => soc.w_affich)}
                                                setOption={setTabsociete}
                                                label="Société"
                                                value={societe}
                                                setValue={setsociete}
                                                inputValue={inputsociete}
                                                setInputValue={setInputsociete}
                                                required
                                            />
                                        </Grid>


                                        <Grid item xs={12} sm={6} >
                                            <ComboApi
                                                id="1"
                                                size="small"
                                                variant="outlined"
                                                options={tabmodule.map((traitement) => traitement.w_affich)}
                                                setOption={settabmodule}
                                                label="Module-Traitement"
                                                value={module}
                                                setValue={setmodule}
                                                inputValue={inputmodule}
                                                setInputValue={setInputmodule}
                                                required
                                            />
                                            {errForm.input == 'module' && <Typography color="red">{errForm.erreur}</Typography>}
                                        </Grid>
                                        <Grid item xs={12} sm={6} display='none'>
                                            <ComboApi id="2" size="small" variant="outlined" options={tabmodule.map((module) => module.w_affich)} setOption={settabmodule} label="Module" value={module} setValue={setmodule} inputValue={inputmodule} setInputValue={setInputmodule} />
                                            {errForm.input == 'module' && <Typography color="red">{errForm.erreur}</Typography>}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField value={objet} onChange={(e) => setObjet(e.target.value)} minRows={5} fullWidth required size="small" variant="outlined" label="Objet"></TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField value={commentaire} onChange={(e) => setCommentaire(e.target.value)} minRows={5} multiline fullWidth required size="small" variant="outlined" label="Description"></TextField>
                                            {errForm.input == 'commentaire' && <Typography color="red">{errForm.erreur}</Typography>}
                                        </Grid>

                                        <Grid item xs={12} style={{ marginTop: '25px' }}>
                                            <FilePond className="HERMESS_mainFile" files={form_filePond} onupdatefiles={setForm_filePond} allowMultiple multiple={true} allowFileEncode={true} server={null} name="mainfile" labelIdle="Déposer votre fichier ici" credits={''} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel control={<Checkbox  value={autreReferant} onChange={(e) => setautreReferant(e.target.checked)} />} label="Autre référant pour cette demande" />
                                </Grid>

                                {autreReferant && (
                                    <>
                                        <Grid item xs={12} lg={4}>
                                            <ComboApi
                                                id="4"
                                                size="small"
                                                variant="outlined"
                                                options={tabemploye.map((employe) => employe.person.trim())}
                                                setOption={settabemploye}
                                                label="Nom Prénom"
                                                value={employe}
                                                setValue={setempploye}
                                                inputValue={inputemploye}
                                                setInputValue={setInputemploye}
                                                useApi={false}
                                                argumentsApi={[account.c_cli.trim()]}
                                                funcApi={appelApiGetSociete}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <TextField value={champMailEmployee} onChange={(e) => setchampMailEmployee(e.target.value)} minRows={5} fullWidth size="small" variant="outlined" label="Mail"></TextField>
                                            {errForm.input == 'champMailEmployee' && <Typography color="red">{errForm.erreur}</Typography>}
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <TextField value={champTelEmployee} onChange={(e) => setchampMailEmployee(e.target.value)} minRows={5} fullWidth size="small" variant="outlined" label="Téléphone"></TextField>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </CardContent>

                        {/**************  Validation Formulaire  ****************/}
                        <CardActions>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Button startIcon={<AddIcon />} onClick={(e) => handleSendForm(e)} variant="contained" color="primary">
                                        Envoyer
                                    </Button>

                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default DemandeNew;

const appelApiGetSociete = async (idSoc, query = '') => {
    let reponse = await axios({
        method: 'get',
        url: query != '' ? API_GET_CLIENT_SOCIETE(idSoc) + '?nomPrenom=' + query : API_GET_CLIENT_SOCIETE(idSoc),
        headers: {
            apiclef: API_KEY,
            authorization: 'Bearer ' + localStorage.getItem('token'),
        },
    });

    return reponse;
};
