import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { API_KEY, API_CHECK_LOGIN, API_GET_DEMANDES } from '../../../config/config';
import authService from '../../../config/utils/authService';
import { demandeSucess } from '../../../redux/actions/actionDemande';
import { push } from 'connected-react-router';
import * as types from '../../actions/actionTypes/demandes';

const getDemandes = (idClient, query) => {
    return axios({
        method: 'get',
        url: `${API_GET_DEMANDES(idClient, query) }`,
        headers: {
            apiclef: API_KEY,
            authorization: 'Bearer ' + localStorage.getItem('token'),
        },
    });
};

export function* workerDemandes(action) {
    try {
        const response = yield call(getDemandes,action.idClient, action.query);

        if (response.data.existe) {
            const demandes = response.data.reponse;

            // IMPORTANT :  permet d'initialiser les data après refresh
            //localStorage.setItem('demandes', JSON.stringify(demandes));

            yield put(demandeSucess(demandes));
            // yield call(push, 'home');
        } else {
            yield put({
                type: types.DEMANDE_ERROR,
                error: response.data, //response.data.erreur_message,
            });
        }
    } catch (error) {
        console.log('ERROR catch get demandes', error);
        yield put({
            type: types.DEMANDE_ERROR,
            error,
        });
    }
}

export function* watcherDemandes() {
    yield takeLatest(types.DEMANDE_REQUEST, workerDemandes);
}
