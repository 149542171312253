/**
 * REDUCER pour la gestion de l'application globale
 * ex : gestion de l'affichage du loader principale
 */
import * as types from '../../actions/actionTypes/app';

export const initialState = {
    displayLoader: false,

    isModalLogoutVisible: false,
    isModalAppVisible: false,

    sidebar: {
        open: false //window.innerWidth > 576 ? true : false
    },

    drawers: {
        left: {
            open: false,
            contentType: '',
            width: 'auto'
        },
        right: {
            open: false,
            contentType: '',
            width: 'auto'
        },
        bottom: {
            open: false,
            contentType: ''
        }
    }
};

export const app = (state = initialState, action) => {
    switch (action.type) {

        // Gestion de l'affichage du loader
        case types.SET_LOADER_VISIBLE:
            return { ...state, displayLoader: true };

        case types.SET_LOADER_HIDDEN:
            return { ...state, displayLoader: false };

        case types.SET_MODAL_LOGOUT_VISIBLE:
            return { ...state, isModalLogoutVisible: true };

        // Gestion de l'affichage de la sidebar
        case types.TOGGLE_SIDEBAR_VISIBILITY:
            let openSidebar = null;
            if (action.open !== undefined) {
                openSidebar = action.open;
            } else {
                openSidebar = !state.sidebar.open;
            }

            return { ...state, sidebar: { open: openSidebar } };


        // Gestion de l'affichage du drawer de droite
        case types.TOGGLE_DRAWER_VISIBILITY:

            //Optimisation de la largeur du drawer en fonction de la largeur de l'écran
            const drawerWidth = window.screen.width <= window.ENV.RESPONSIVE.mobileBP ? window.screen.width + 'px' : 'auto';

            switch (action.drawerTarget) {
                case 'left':
                    return {
                        ...state,
                        drawers: {
                            ...state.drawers,
                            left: {
                                ...state.drawers.left,
                                open: action.open,
                                contentType: action.contentType,
                                width: drawerWidth
                            }
                        }
                    }
                case 'right':
                    return {
                        ...state,
                        drawers: {
                            ...state.drawers,
                            right: {
                                ...state.drawers.right,
                                open: action.open,
                                contentType: action.contentType,
                                width: drawerWidth
                            }
                        }
                    }
                case 'bottom':
                    return {
                        ...state,
                        drawers: {
                            ...state.drawers,
                            bottom: {
                                ...state.drawers.bottom,
                                open: action.open,
                                contentType: action.contentType,
                                width: drawerWidth
                            }
                        }
                    }

                default:
                    return state;
            }

        default:
            return state;
    }
}

export default app;