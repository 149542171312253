import React from "react";
import SVG_LOADER from '../../assets/images/svg/loader_square.svg';

const Loader = ({ fullHeight = 1, size = '', style = {} }) => {

    let cssClass = `reactor-container ${fullHeight === 1 ? 'fullpage-wrapper' : ''} ${size}`;

    return (
        <div style={style} className={cssClass}>
            <img src={SVG_LOADER} alt="loader" />
        </div>
    );
}

export default Loader;
