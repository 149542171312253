import * as types from './actionTypes/demandes';

export const demandeRequeste = (idClient, query) => ({
    type: types.DEMANDE_REQUEST,
    query,
    idClient
});

export const demandeSucess = (demandes) => ({
    type: types.DEMANDE_SUCCESS,
    demandes,
});

export const demandeError = (err) => ({
    type: types.DEMANDE_ERROR,
    err,
});

export const clearMessages = () => ({
    type: types.CLEAR_MESSAGES,
});

export const demandeMajVu = (person, ordre) => ({
    type: types.DEMANDE_MODIF_VU,
    person,
    ordre,
});

export const demandeUpdatePagination = (pagination) => ({
    type: types.DEMANDE_UPDATE_PAGINATION,
    pagination,
});

export const demandeUpdateFiltres = (filtres) => ({
    type: types.DEMANDE_UPDATE_FILTRE,
    filtres,
});
