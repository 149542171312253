import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { IMG_USER_PATH, IMG_USER_DEFAULT } from '../../../constants/config';
// import * as selectorAuth from '../../../selectors/auth';
import USER_BOY_SVG from '../../../assets/images/svg/user-boy.svg';
import USER_GIRL_SVG from '../../../assets/images/svg/user-girl.svg';
import Popover from '@mui/material/Popover';
import LockIcon from '@mui/icons-material/Lock';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { Link } from 'react-router-dom';

function Avatar({ account, idPointageStatus }) {
    const [anchorEl, setAnchorEl] = useState(null);

    if (account === null || account === undefined) return null; // ??? pass twice : first pass, state is null...

    const role = account.admin === 1 ? 'admin' : 'user';

    // const photo = account.photo;
    // let picture = photo !== null && photo !== '' ? IMG_USER_PATH + photo : IMG_USER_PATH + IMG_USER_DEFAULT;
    // let picture = account.avatar;//TODO;

    let picture = account.titre.includes('Madame') ? USER_GIRL_SVG : USER_BOY_SVG;

    //picture = TONY_STARK;

    const classRole = `role ${role}`;

    const showGestionContact = (account.filtres["Responsable"] === "1" || account.filtres["Directeur"] === "1"  || account.filtres["CompteRendu Support"] === "1" ||  account.filtres["Recoit_cc_mail_auto"] === "1" || account["admin"] === 1  ) ? true : false

    const openPopup = Boolean(anchorEl);

    const handlePopover = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const subMenu = () => {
        const fullname = `${account.prenom} ${account.nom}`;

        return (
            <ul className="avatar-subMenu">
                <li className="header">{fullname}</li>
                <li className="link">
                    <Link to="/formChangePassword" className="button-link">
                        <LockIcon />
                        <span>Modifier mes informations personnelles</span>
                    </Link>
                </li>
                {showGestionContact && (<li className="link">
                    <Link to="/gestionContact" className="button-link">
                        <PermContactCalendarIcon />
                        <span> Gestion des contacts société</span>
                    </Link>
                </li>)}
                {/* 
                {((window.ENV.ENVIRONMENT === 'DEV' || window.ENV.ENVIRONMENT === 'DEMO') && account.manager === 1) &&

                    <li className="link">
                        <Link to='/manageDelegation' className="button-link">
                            <SupervisorAccountIcon /><span>{window.ENV.getDico('delegationManagement', true)}</span>
                        </Link>
                    </li>
                } */}
            </ul>
        );
    };

    return (
        <React.Fragment>
            <div className="avatar" onClick={(e) => handlePopover(e)}>
                <div className="picture" style={{ backgroundImage: `url(${picture})` }}>
                    {account.isTec && (
                        <div className="role initiales">
                            {account.prenom.substring(0, 1)} {account.nom.substring(0, 1)}
                        </div>
                    )}

                    {account.code_acces.includes('COMMUN') && <div className="role  supervisor"></div>}
                </div>
                <div className="fullname">
                    <span className="firstname">{account.prenom.toLowerCase()}</span>
                    <span className="lastname">{account.nom}</span>
                </div>
                {/* <div className="pointage-status">
                    <div className="icon-status"></div>
                    <div className="title-status">En pause</div>
                </div> */}
            </div>
            <Popover
                open={openPopup}
                anchorEl={anchorEl}
                onClick={(e) => e.stopPropagation()}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                {subMenu()}
            </Popover>
        </React.Fragment>
    );
}

Avatar.propTypes = {
    account: PropTypes.object.isRequired,
};

export default Avatar;
