import React from 'react';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import { toggleDrawerVisibility } from '../../../redux/actions/actionsApp';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function DrawerBottomContent() {

    const contentType = useSelector(state => state.app.drawers.bottom.contentType);
    const drawerWidth = useSelector(state => state.app.drawers.bottom.width);
    let height = 'auto';
    const dispatch = useDispatch();

    let drawerContent = null;

    switch (contentType.toUpperCase()) {

        //Gestion des composants à afficher dans le DRAWER

        // case 'TROMBINOSCOPE':
        //     drawerContent = <Trombinoscope />;
        //     break;
        // case 'MYTEAM':
        //     drawerContent = <Trombinoscope title={window.ENV.getDico('myTeam')} view="MYTEAM" />;
        //     break;
        // case 'REQUEST_HISTORY':
        //     drawerContent = (
        //         <React.Fragment>
        //             <h2>{window.ENV.getDico('requestHistory')}</h2>
        //             <TableRequestHistory />
        //         </React.Fragment>
        //     );
        //     break;
        // case 'TEAM_SCHEDULER':
        //     drawerContent = <SchedulePage title="Planning" view="MYTEAM" />;
        //     height = '100vh';
        //     break;
        default:
            drawerContent = <h2>View does not exist...</h2>;
            break;
    }

    return (
        <div className="container-drawer drawer-bottom" style={{ width: drawerWidth, height }}>
            <IconButton aria-label="delete" className="btn-close-drawer" onClick={() => dispatch(toggleDrawerVisibility('bottom', false))}>
                <ArrowDownwardIcon fontSize="large" />
            </IconButton>

            {drawerContent}

        </div>
    )
}

export default DrawerBottomContent;
