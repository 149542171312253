import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector, useDispatch } from 'react-redux';
import { toggleDrawerVisibility } from '../../../redux/actions/actionsApp';

function DrawerLeftContent() {

    const dispatch = useDispatch();
    const contentType = useSelector(state => state.app.drawers.left.contentType);
    const drawerWidth = useSelector(state => state.app.drawers.left.width);
    const account = useSelector(state => state.modAuth.account);

    let drawerContent = null;

    switch (contentType.toUpperCase()) {
        // case 'PROFILE_WEEK_DETAIL':
        //     drawerContent = <PanelDayHour readOnly={account.manager === 1 || account.RH === 1 ? false : true} />;
        //     break;
        default:
            drawerContent = <h2>View does not exist...</h2>;
            break;
    }


    return (
        <div className="container-drawer drawer-left" style={{ width: drawerWidth }}>
            <IconButton aria-label="delete" className="btn-close-drawer" onClick={() => dispatch(toggleDrawerVisibility('left', false))}>
                <ArrowBackIcon fontSize="large" />
            </IconButton>

            {drawerContent}
        </div>
    )
}

export default DrawerLeftContent;
