import React from 'react';
import { VERSION, ENV } from './../../config/config';

function MentionCorporate() {
    return (
        <div className="mention-corporate">
            scepia 2022 © DevTeam App v{VERSION}
            {ENV === 'DEV' &&
                <span className="debug-info"> - ({ENV})</span>
            }
        </div>
    )
};

export default MentionCorporate;
