import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, Button, TextField, Card, CardContent, Radio, RadioGroup, FormControlLabel, FormControl, CardActions, Divider, Autocomplete, LinearProgress, Typography } from '@mui/material';
import { AccountBalanceOutlined, Search as SearchIcon } from '@mui/icons-material';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import FormLabel from '@mui/material/FormLabel';
import { setLoaderHidden, setLoaderVisible } from '../../../../redux/actions/actionsApp';
import { mailRequeste, mailUpdateFiltres, mailUpdatePagination } from '../../../../redux/actions/actionMails';
import MyPagination from '../pagination';
import MailItem from './mailItem';
import { useQuery } from '../demandes/recherche';

toast.configure();

const MailsEnAttente = () => {
    /**************************** HOOKS ********************************/
    const dispatch = useDispatch();
    const urlQuery = useQuery();

    /**************************** DONNEE RECUPERE DEPUIS LE STORE ********************************/
    const account = useSelector((state) => state.modAuth.account);
    const loadingmails = useSelector((state) => state.mails.loading);
    const errormails = useSelector((state) => state.mails.error);
    const mailsStore = useSelector((state) => state.mails.mails);
    const pageEncours = useSelector((state) => state.mails.pagination);
    const filtres = useSelector((state) => state.mails.filtresRecherche);

    /** Pour bloquer l'affichage multiple de toast */
    const [toastDisplay, settoastDisplay] = useState(true);

    /** */
    // Récuperation des query de l'url pour les filtres
    const querydateRech = urlQuery.get('dateRech');
    const querydateFinRech = urlQuery.get('dateFinRech');
    const querycontientRech = urlQuery.get('contientRech');
    const querytri = urlQuery.get('tri');
    const [dateDeb, setDateDeb] = useState(filtres && filtres.dateDeb ? filtres.dateDeb : '');
    const [dateFin, setDateFin] = useState(filtres && filtres.dateFin ? filtres.dateFin : '');
    const [contains, setContains] = useState(filtres && filtres.contains ? filtres.contains : null);
    const [typeTri, setTypeTri] = useState(filtres && filtres.typeTri ? filtres.typeTri : '1');

    /** Mail en cours  pour la pagination  */
    const [mailPagination, setmailPagination] = useState([]);

    /** Met à jour la page de la pagination dans le store*/
    const updatePage = (page) => {
        dispatch(mailUpdatePagination(page));
    };

    /**************************** FONCTI0N SUBMIT ********************************/
    const handleSubmit = async (e = null) => {
        dispatch(setLoaderVisible());
        if (e) e.preventDefault();

        let query = '';
        query += query == '' ? (!contains ? '' : 'contientRech=' + contains) : !contains ? '' : '&contientRech=' + contains;
        query += query == '' ? (typeTri == '' ? '' : 'tri=' + typeTri) : typeTri == '' ? '' : '&tri=' + typeTri;
        query += query == '' ? (dateDeb == '' ? '' : 'dateRech=' + moment(dateDeb).format('YYYY/MM/DD')) : dateDeb == '' ? '' : '&dateRech=' + moment(dateDeb).format('YYYY/MM/DD');
        query += query == '' ? (dateFin == '' ? '' : 'dateFinRech=' + moment(dateFin).format('YYYY/MM/DD')) : dateFin == '' ? '' : '&dateFinRech=' + moment(dateFin).format('YYYY/MM/DD');

        query = query == '' ? '' : '?' + query.replaceAll(' ', '%20');

        //Saga mails
        dispatch(mailRequeste(account.person.trim(), account.mail.trim(), account.c_cli.trim(), query));
        dispatch(mailUpdateFiltres({ dateDeb, dateFin, contains, typeTri }));

        dispatch(setLoaderHidden());
    };

    useEffect(() => {
        if (!mailsStore) {
            let query = '';
            query += query == '' ? (!contains ? '' : 'contientRech=' + contains) : !contains ? '' : '&contientRech=' + contains;
            query += query == '' ? (typeTri == '' ? '' : 'tri=' + typeTri) : typeTri == '' ? '' : '&tri=' + typeTri;
            query += query == '' ? (dateDeb == '' ? '' : 'dateRech=' + moment(dateDeb).format('YYYY/MM/DD')) : dateDeb == '' ? '' : '&dateRech=' + moment(dateDeb).format('YYYY/MM/DD');
            query += query == '' ? (dateFin == '' ? '' : 'dateFinRech=' + moment(dateFin).format('YYYY/MM/DD')) : dateFin == '' ? '' : '&dateFinRech=' + moment(dateFin).format('YYYY/MM/DD');

            query = query == '' ? '' : '?' + query.replaceAll(' ', '%20');

            dispatch(mailRequeste(account.person.trim(), account.mail.trim(), account.c_cli.trim(), query));
        }
    }, []);

    useEffect(() => {
        if (!toastDisplay) return;
        if (errormails) {
            settoastDisplay(false);
            toast.error("Aucune demande en attente n'a été trouvé", {
                position: toast.POSITION.BOTTOM_RIGHT,
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                onClose: () => settoastDisplay(true),
            });
        }
    }, [errormails]);

    /**  Use effect affichage loader **/
    useEffect(() => {
        if (loadingmails) {
            dispatch(setLoaderVisible());
        } else {
            dispatch(setLoaderHidden());
        }
    }, [loadingmails]);
    return (
        <React.Fragment>
            <Grid container spacing={2} className="container-demandes">
                <Grid item xs={12}>
                    <h1>Demandes non traitées </h1>
                </Grid>
                {/* <Grid item xs={12} lg={2}>
                    <Button onClick={(e) => history.push('/demandes')} variant="outlined" color="primary" fullWidth>
                        Voir mes demandes
                    </Button>
                </Grid> */}
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={4}>
                                    <TextField fullWidth value={contains} onChange={(e) => setContains(e.target.value)} label="L'objet du mail contient" size="small" variant="outlined"></TextField>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <TextField fullWidth value={dateDeb} onChange={(e) => setDateDeb(e.target.value)} label="Du" type="date" size="small" variant="outlined" InputLabelProps={{ shrink: true }} ></TextField>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <TextField fullWidth value={dateFin} onChange={(e) => setDateFin(e.target.value)} label="Au" type="date" size="small" variant="outlined" InputLabelProps={{ shrink: true }} ></TextField>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <CardActions>
                            <Grid container spacing={2} className="setcion-recherche">
                                <Grid item xs={12} lg={6}>
                                    <FormLabel component="legend">Trier par</FormLabel>
                                    <RadioGroup row aria-label="radioTri" name="radioTri" defaultValue="1" value={typeTri} onChange={(event) => setTypeTri(event.target.value)}>
                                        <FormControlLabel value="1" control={<Radio />} label="Date de création décroissant" />
                                        <FormControlLabel value="2" control={<Radio />} label="Date de création croissant" />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12} lg={6} className="div-recherche">
                                    <Button startIcon={<SearchIcon />} onClick={(e) => handleSubmit(e)} variant="contained" color="primary">
                                        Rechercher
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>

            {mailsStore && (
                <Grid container>
                    {/* Pagination */}
                    <MyPagination totalDemande={mailsStore.length} setDemandes={setmailPagination} demandesStore={mailsStore} demandes={mailPagination} pageEncours={pageEncours} setpageEncours={updatePage}></MyPagination>
                    {/* Liste des demandes */}
                    {mailPagination && (
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {mailPagination.map((_mail, index) => {
                                    return (
                                        <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                                            <MailItem item={_mail} /*onItemSelect={handleSelectDemande}*/ userConnect={account} />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            {/* Pagination Suivant */}
                        </Grid>
                    )}
                </Grid>
            )}
        </React.Fragment>
    );
};

export default MailsEnAttente;
