import { Grid, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_GET_PRODUIT, API_GET_PRODUIT_VERSION, API_GET_TYPE_APPEL } from '../../config/config';
import { setLoaderHidden, setLoaderVisible } from '../../redux/actions/actionsApp';
import ComboApi from '../Demandes/component/comboApi';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { GetApp as GetAppIcon, ArrowDropUp as ArrowDropUpIcon } from '@mui/icons-material';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { appelApiGet } from '../../config/utils/apiFunction';

const Telechargement = () => {
    /**************************** DISPATCH ********************************/
    const dispatch = useDispatch();

    /**************************** DONNEE RECUPERE DEPUIS LE STORE ********************************/
    const account = useSelector((state) => state.modAuth.account);

    //  Produit
    const [produit, setProduit] = useState('');
    const [inputproduit, setInputproduit] = useState('');
    const [tabproduit, settabproduit] = useState([]);

    // Table téléchargement
    const [tabTelechargement, settabTelechargement] = useState([]);

    /**************************** USE EFFECT COMPOSANT INIT ********************************/
    useEffect(() => {
        if (!produit) return;
        (async () => {
            try {
                /******************************* On récupére les version des produits ********************** */
                let produitSelect = tabproduit.find((_produit) => _produit.lib_logiciel.trim() == produit.trim());
                let url = `${API_GET_PRODUIT_VERSION(produitSelect.c_logiciel)}`;
                let reponse = await appelApiGet(url);
                if (!reponse.data.existe) {
                    console.log('Aucune version pour ce logiciel');
                }
                console.log('settabTelechargement', reponse.data.reponse);
                settabTelechargement(reponse.data.reponse);
            } catch (error) {
                console.log('error useeffect catch', error);
            }
        })();
    }, [produit]);

    /**************************** USE EFFECT COMPOSANT INIT ********************************/
    useEffect(() => {
        dispatch(setLoaderVisible());

        (async () => {
            try {
                /******************************* On récupére les produits du client********************** */
                let url = `${API_GET_PRODUIT + (account.isTec ? '' : '?codeAcces=' + account.code_acces)}`;
                let reponse = await appelApiGet(url);
                settabproduit(reponse.data.reponse);
                console.log('produit', reponse.data.reponse[0].lib_logiciel);
                setProduit(reponse.data.reponse[0].lib_logiciel);
                console.log('tabproduit', reponse.data);
            } catch (error) {
                console.log('error useeffect catch', error);
            }
        })();

        dispatch(setLoaderHidden());
    }, []);
    return (
        <>
            <h1>Téléchargement</h1>

            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <ComboApi id="1" size="small" variant="outlined" options={tabproduit.map((produit) => produit.lib_logiciel)} setOption={settabproduit} label="Produit" value={produit.lib_logiciel} setValue={setProduit} inputValue={inputproduit} setInputValue={setInputproduit} />
                </Grid>
                <Grid item xs={12}>
                    <MyTable tabTelechargement={tabTelechargement} />
                </Grid>
            </Grid>
        </>
    );
};

export default Telechargement;

/*******************************************************  TAble téléchargement  */

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#1976d2',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const MyTable = ({ tabTelechargement }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">Version</StyledTableCell>
                            <StyledTableCell align="left">Date</StyledTableCell>
                            <StyledTableCell align="left"> </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0 ? tabTelechargement.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : tabTelechargement).map((telechargement, i) => (
                            <StyledTableRow key={i}>
                                <StyledTableCell align="left">{telechargement.numero}</StyledTableCell>
                                <StyledTableCell align="left">{telechargement.datev}</StyledTableCell>
                                <StyledTableCell align="left">
                                    <Button onClick={(e) => null}>
                                        <GetAppIcon />
                                    </Button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={3}
                                count={tabTelechargement.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
};
