export class File {

    /**
     * 
     * @param {*} b64Data Contenu du fichier encodé en base 64
     * @param {*} contentType contentType du fichier
     * @param {*} sliceSize Tranche de trairement des 'byteCharacters' plutot que d'un seul coup (par défaut 512)
     * @returns 
     */
    static b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    /**
     * Récupère le contenu d'un fichier pour le créer et le télécharger sur le navigateur
     * @param {*} DATAFile Contenu du fichier 
     * @param {string} fileName Nom du fichier 'par défaut 'file_date()'
     * @param {string} dataType contentType du fichier (par défaut 'binary')
     * @param {boolean} bFileEncodeBase64 Contenu encodé en base 64 ou non ?
     */
    static downloadFile = (DATAFile, fileName = null, dataType = null, bFileEncodeBase64 = false) => {

        // Création du document
        const link = document.createElement('a')
        let blob

        /// Vérification des informations et mise en forme
        fileName = fileName ? fileName : `file_${Date()}` // Nom du fichier
        dataType = dataType ? dataType :'binary'

        if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            if (bFileEncodeBase64) {
                blob = this.b64toBlob(DATAFile, dataType);
            }
            else {
                blob = new Blob([DATAFile], { type: dataType })
            }
            
            const url = URL.createObjectURL(blob);

            link.setAttribute('href', url)
            link.setAttribute('download', fileName)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }
}