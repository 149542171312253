import axios from 'axios';
import { API_GED_IDENTIFIE, API_KEY, API_KEY_GED } from '../config';

export const appelApiGet = async (url) => {
    let reponse = await axios({
        method: 'get',
        url,
        headers: {
            apiclef: API_KEY,
            authorization: 'Bearer ' + localStorage.getItem('token'),
        },
    });

    return reponse;
};

export const appelApiGedGet = async (url) => {
    let reponse = await axios({
        method: 'get',
        url,
        headers: {
            authorization: 'Bearer ' + (await getJwtGed()),
        },
    });

    return reponse;
};

export const getJwtGed = async (utilisateur = 'fred', motdepasse = 'nope') => {
    let reponse = await axios({
        method: 'get',
        url: API_GED_IDENTIFIE,
        headers: {
            apiclef: API_KEY_GED,
            utilisateur,
            motdepasse,
        },
    });
    console.log('reponse getJwtGed', reponse.data);
    return reponse.data.reponse.jeton;
};
