import React from 'react';
import Drawer from '@mui/material/Drawer';
import { toggleDrawerVisibility } from '../../../redux/actions/actionsApp';
import { useSelector, useDispatch } from 'react-redux';

import DrawerRightContent from './DrawerRightContent';
import DrawerBottomContent from './DrawerBottomContent';
import DrawerLeftContent from './DrawerLeftContent';


function DrawerManagement() {

    const dispatch = useDispatch();

    const drawerLeftState = useSelector(state => state.app.drawers.left.open);
    const drawerRightState = useSelector(state => state.app.drawers.right.open);
    const drawerBottomState = useSelector(state => state.app.drawers.bottom.open);

    return (
        <React.Fragment>

            {/* LEFT DRAWER */}
            <Drawer
                // variant="persistent" // ¨Permet de ne pas avoir de masque mais prb bug render des data???
                SlideProps={{
                    unmountOnExit: true
                }}
                anchor="left"
                open={drawerLeftState}
                onClose={() => dispatch(toggleDrawerVisibility('left', false))}
            >
                <DrawerLeftContent />
            </Drawer>

            {/* RIGHT DRAWER */}
            <Drawer
                anchor="right"
                open={drawerRightState}
                onClose={() => dispatch(toggleDrawerVisibility('right', false))}
            >
                <DrawerRightContent />
            </Drawer>

            {/* BOTTOM DRAWER */}
            <Drawer
                anchor="bottom"
                open={drawerBottomState}
                onClose={() => dispatch(toggleDrawerVisibility('bottom', false))}
            // onOpen={() => dispatch(toggleDrawerVisibility('bottom', true))}
            >
                <DrawerBottomContent />
            </Drawer>
        </React.Fragment>
    )
}

export default DrawerManagement;
