import { Grid, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_GET_PRODUIT, API_GET_PRODUIT_VERSION, API_GET_TYPE_APPEL, API_GET_VIDEO_LISTE, API_GET_VIDEO_STREAM } from '../../config/config';
import { setLoaderHidden, setLoaderVisible } from '../../redux/actions/actionsApp';
import ComboApi from '../Demandes/component/comboApi';
import ReactPlayer from 'react-player';
import { appelApiGedGet, appelApiGet } from '../../config/utils/apiFunction';

const VideoFormation = () => {
    const dispatch = useDispatch();

    const account = useSelector((state) => state.modAuth.account);
    /** Etat locale   */
    const [url, setUrl] = useState('');
    // Combo Video
    const [video, setvideo] = useState('');
    const [inputvideo, setInputvideo] = useState('');
    const [tabvideo, settabvideo] = useState([]);

    /******************************* On récupére les vidéos ********************** */
    useEffect(() => {
        dispatch(setLoaderVisible());

        (async () => {
            try {
                let url = `${API_GET_VIDEO_LISTE('video,formation')}`;
                let reponse = await appelApiGedGet(url);
                if (!reponse.data) {
                    return;
                }
                settabvideo(reponse.data.reponse);
                console.log('videos', reponse.data.reponse);
                setvideo(reponse.data.reponse[0].titre);
            } catch (error) {
                console.log('error useeffect catch', error);
            }
        })();

        dispatch(setLoaderHidden());
    }, []);

    useEffect(() => {
        if (!tabvideo.length > 0) return;

        (async () => {
            let _id = tabvideo.find((_video) => _video.titre == video)._id;
            console.log('id', _id);

            setUrl(await API_GET_VIDEO_STREAM(_id));
        })();
    }, [video]);

    useEffect(() => {
        console.log('url video stream', url);
    }, [url]);

    return (
        <>
            <h1>Visionner une vidéo de formation</h1>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <ComboApi id="a" size="small" variant="outlined" options={tabvideo.map((video) => video.titre)} setOption={settabvideo} label="Liste des vidéos" value={video} setValue={setvideo} inputValue={inputvideo} setInputValue={setInputvideo} />
                </Grid>
                <Grid item xs={12}>
                    <ReactPlayer width={'100%'} height={'500px'} controls url={url} />
                </Grid>
            </Grid>
        </>
    );
};

export default VideoFormation;

//url="http://localhost:8081/stream?_id=6155dd1ebb2cc4626487b514&jeton=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI3N2JyYzB1amVza3FvN2xiM3Y0YjdpZHZlOGxtM3NtbSIsInV0aWxpc2F0ZXVyIjoiZnJlZCIsInRvcFNlY3JldCI6IlNtdXJ0emxlRlRXIiwiaWF0IjoxNjI3Mzk5NTI5LCJleHAiOjE2Mjc1NzIzMjl9.ImUaX6Ftjn8ILPiQX4j1-rVIwvTkFuYAUiXb8nrql6M"
