export const MAIL_REQUEST = 'MAIL_REQUEST';
export const MAIL_SUCCESS = 'MAIL_SUCCESS';
export const MAIL_ERROR = 'MAIL_ERROR';
export const MAIL_ADD = 'MAIL_ADD';

export const GET_MAIL_REQUEST = 'GET_MAIL_REQUEST';
export const GET_MAIL_SUCCESS = 'GET_MAIL_SUCCESS';
export const GET_MAIL_ERROR = 'GET_MAIL_ERROR';

export const MAIL_UPDATE_PAGINATION = 'MAIL_UPDATE_PAGINATION'
export const MAIL_UPDATE_FILTRE = 'MAIL_UPDATE_FILTRE'


export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
