/**
 * Déclaration des constantes
 */

import base64url from 'base64url';
import { getJwtGed } from './utils/apiFunction';
import { Buffer } from 'buffer';

export const VERSION = '1.1.3b';

export const ENV = process.env.REACT_APP_ENV;

//export const ENV = 'LOCAL';

// WEB SERVICE CONFIGURATION
//export const API_KEY = 'sgou8y4gh54xeqvtvabaxtywdjb785px';
export const API_URI_LOCAL = 'http://localhost:8080/';
export const API_URI_DEV = 'https://debian-dev.scepia.fr/hermessapi/v1-dev/';
export const API_URI_RECETTE = 'https://debian-dev.scepia.fr/hermessapi/v1-recette/';
export const API_URI_DEMO = 'https://demo.scepia.fr/PH/V1/';
export const API_URI_PROD = 'https://api.scepia.fr/hermess/v1/';

export const API_URI_GED_LOCAL = 'https://debian-dev.scepia.fr/minigedapi/v2-dev/'; // 'http://localhost:8081/';
export const API_URI_GED_DEV = 'https://debian-dev.scepia.fr/minigedapi/v2-dev/';
export const API_URI_GED_RECETTE = 'https://debian-dev.scepia.fr/minigedapi/v2-dev/';
export const API_URI_GED_DEMO = '';
export const API_URI_GED_PROD = 'https://api.scepia.fr/miniged/v1/';

const CONF = {
    getAppEnv: function () {
        switch (ENV) {
            case 'LOCAL':
                return {
                    API_URI: API_URI_LOCAL,
                    API_KEY: '5t1hc4qyfmfvc3a10dm0rt5ijvxpoeq6',
                    API_URI_GED: API_URI_GED_LOCAL,
                    API_KEY_GED: '77brc0ujeskqo7lb3v4b7idve8lm3smm',
                };
            case 'DEV':
                return {
                    API_URI: API_URI_DEV,
                    API_KEY: '5t1hc4qyfmfvc3a10dm0rt5ijvxpoeq6',
                    API_URI_GED: API_URI_GED_DEV,
                    API_KEY_GED: '77brc0ujeskqo7lb3v4b7idve8lm3smm',
                };
            case 'RECETTE':
                return {
                    API_URI: API_URI_RECETTE,
                    API_KEY: '5t1hc4qyfmfvc3a10dm0rt5ijvxpoeq6',
                    API_URI_GED: API_URI_GED_RECETTE,
                    API_KEY_GED: '77brc0ujeskqo7lb3v4b7idve8lm3smm',
                };
            case 'DEMO':
                return {
                    API_URI: API_URI_DEMO,
                    API_KEY: 'g49boikb3w35tah0nr3biiamw9lm3vei',
                    API_URI_GED: API_URI_GED_DEMO,
                    API_KEY_GED: '',
                };
            case 'PROD':
                return {
                    API_URI: API_URI_PROD,
                    API_KEY: 'bbxpwd14z4xs2s2sbmglibg0byg9rg18',
                    API_URI_GED: API_URI_GED_PROD,
                    API_KEY_GED: 'kmow7admb5c06gq06nz0jhqmoowdhv8f',
                };
            default:
                return { API_URI: API_URI_DEV };
        }
    },
};

console.log('uri', CONF.getAppEnv().API_URI);

//

export const API_URI = CONF.getAppEnv().API_URI;
export const API_KEY = CONF.getAppEnv().API_KEY; //'sgou8y4gh54xeqvtvabaxtywdjb785px';
export const API_URI_GED = CONF.getAppEnv().API_URI_GED;
export const API_KEY_GED = CONF.getAppEnv().API_KEY_GED;
/**************************************************************************
 *  API
 ***************************************************************************/
export const API_CHECK_LOGIN = API_URI + 'identifier';
export const API_GET_PRODUIT = API_URI + 'clients/produits';
export const API_GET_VARGEN = (c_var) => API_URI + `parametrage/var_g/${c_var}`;
export const API_GET_PRODUIT_VERSION = (idApp) => API_URI + `produits/versions/${idApp}`;
export const API_GET_PRODUIT_LOCALISATION = (idApp) => API_URI + `produits/localisations/${idApp}`;

export const API_GET_DEMANDES = (idClient, querry) => API_URI + `demandes/${idClient + querry}`;
//export const API_GET_DEMANDES = API_URI + 'demandes';
export const API_GET_MAIL_ATTENTE = (idClient, mail, c_soc) => API_URI + `clients/${idClient}/demandeAttente/${mail}/${c_soc}`;
export const API_GET_MAIL_ATTENTE_DETAIL = (idClient, id) => API_URI + `clients/${idClient}/demandeAttente/detail/${id}`;
export const API_GET_TEC_ALL = API_URI + 'tec';
export const API_GET_CLIENT_ALL = API_URI + 'tec/clients';
export const API_GET_SOCIETE_ALL = API_URI + 'tec/societe';
export const API_GET_TYPE_APPEL = API_URI + 'typeappel';
export const API_GET_DETAIl_DEMANDE = (ordre, idClient) => API_URI + `clients/${idClient}/tickets/${ordre}`;
export const API_GET_TOTAL_DEMANDE = (email, idClient, query = '') => API_URI + `clients/${idClient}/demandes/etats/${email}${query == '' ? '' : query}`;
export const API_GET_DOCUMENT = (idFichier) => API_URI + `documents?cheminFichier=` + Buffer.from(idFichier.trim(), 'utf8').toString('base64'); /*  base64url.encode(idFichier.trim()) Buffer.from(idFichier.trim(), 'ascii').toString('base64'); */
export const API_PUT_DETAIL_DEMANDE = (idClient) => API_URI + `clients/${idClient}/demandes`;
export const API_POST_DEMANDE = (idClient) => API_URI + `clients/${idClient}/tickets/nouveau`;
export const API_PATCH_CONTACT_CLIENT = (idsoc, idClient) => API_URI + `client_societes/${idsoc}/${idClient}`;
export const API_PUT_DEMANDE_CLOTURE = (idClient) => API_URI + `clients/${idClient}/demandes/clotures`;
export const API_PUT_VUE = (idClient, idTicket) => API_URI + `clients/${idClient}/lu_messages/${idTicket}`;
export const API_POST_MAIL = (idClient) => API_URI + `clients/${idClient}/mail`;
export const API_POST_PASSWORD = (idClient) => API_URI + `clients/${idClient}/password`;
export const API_GET_CLIENT_SOCIETE = (idSoc) => API_URI + `client_societes/${idSoc}`;
export const API_MOT_DE_PASSE_OUBLIE = API_URI + `motdepasseoublie`;
export const API_GET_CONTRATS = (idClient, idSoc, query) => API_URI + `contrats/${idClient}/${idSoc}${query == '' ? '' : query}`;
export const API_GET_CONTRATS_CALCUL_UNITE = (idClient, idcontrat) => API_URI + `contrats/${idClient}/unitee/${idcontrat}`;

// GED API
export const API_GET_VIDEO_LISTE = (workspace = '') => API_URI_GED + `document${workspace ? '?workspace=' + workspace : ''}`;
export const API_GED_IDENTIFIE = API_URI_GED + 'identification';
export const API_GET_VIDEO_STREAM = async (id) => API_URI_GED + `stream?jeton=${await getJwtGed()}&_id=${id}`;

export const API_GET_GED_NEWUSER_STREAM = async (id) => API_URI_GED + `stream?jeton=${await getJwtGed()}&_id=${id}`;
export const API_GET_GED_NEWUSER_LISTE = (workspace = '') => API_URI_GED + `document${workspace ? '?workspace=' + workspace : ''}`;


/**
 * Données source du menu de gauche (groupe + liens)
 */
export const menuDataSource = [
    {
        group: {
            id: 'MOD_HOME',
            title: 'Accueil',
            displayHeader: 0,
        },
        links: [
            {
                title: 'Accueil',
                route: '/home',
                icon: 'icon-home-scepia',
            },
        ],
    },
    {
        group: {
            id: 'MOD_DEMANDE',
            title: 'Demandes',
            shortTitle: '',
            displayHeader: 1,
        },
        links: [
            {
                title: 'Mes demandes',
                shortTitle: 'Demandes',
                icon: 'icon-mail',
                route: '/demandes',
            },
            {
                title: 'Nouvelle demande',
                shortTitle: 'Faire une demande',
                icon: 'icon-request',
                route: '/nouvelleDemande/0/0/0/0',
            },
            {
                title: 'Demandes non traitées',
                shortTitle: 'Voir les demandes pas encore traité par Scepia',
                icon: 'icon-request-history',
                route: '/mails',
            },
        ],
    },
    {
        group: {
            id: 'modOutil',
            title: 'Outils',
            shortTitle: '',
            displayHeader: 1,
        },
        links: [
            {
                title: 'Video',
                shortTitle: 'Vidéo de formation',
                icon: 'icon-video',
                route: '/video',
            },
            // {
            //     title: 'Téléchargement',
            //     shortTitle: 'Télécharger des patchs et versions',
            //     icon: 'icon-download',
            //     route: '/telechargement',
            // },
        ],
    },
    {
        group: {
            title: 'Général',
            displayHeader: 0,
            rights: 1,
        },
        links: [
            {
                title: 'Se déconnecter',
                route: '/logout',
                icon: 'icon-logout',
                rights: 1,
            },
        ],
    },
];
