import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Grid, Button, TextField, Typography, Accordion, AccordionSummary, AccordionDetails, AccordionActions, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { setLoaderHidden, setLoaderVisible } from '../../../../redux/actions/actionsApp';
import { FilePond, registerPlugin } from 'react-filepond'; // File
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { File } from '../../../../config/utils/file';
import { API_GET_MAIL_ATTENTE_DETAIL } from '../../../../config/config';
import { appelApiGet } from '../../../../config/utils/apiFunction';
import BackIcon from '../../../../assets/images/svg/back-arrow-black.svg'

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType);
toast.configure();

const MailDetail = () => {
    /** Hook permettant de switcher de composant avec une url */
    const history = useHistory();

    /** Hooks permettant de récupérer les parmaètre dans une url  */
    const paramsUrl = useParams();

    /** Etat locale de la demande */
    const [demande, setDemande] = useState();
    const [destinataires, setDestianataires] = useState([]);
    
    let tab_split_date = demande && demande.d_reception ?  moment(demande.d_reception, 'YYYY/MM/DD HH:mm').format('DD/MM/YYYY HH:mm').split(' ') : []
    const date_demande = tab_split_date.length > 0 ? (tab_split_date[0] + ' à ' + tab_split_date[1]) : ''

    /** Etat locale des paramètres de l'url */
    const [id] = useState(paramsUrl.id);
    const [idUser] = useState(paramsUrl.idUser);

    /**Chaque fois la demande change onrécupères les destianataire dans un tableau  */
    useEffect(() => {
        if (!demande) return;

        setDestianataires(demande.destinataires.split('/'));
    }, [demande]);
    /**On récupère les information de la demande à l'initialisation du composent  */
    useEffect(() => {
        try {
            (async () => {
                const detailDemande = await appelApiGet(API_GET_MAIL_ATTENTE_DETAIL(idUser, id));
                if (!detailDemande.data.existe) {
                    console.log('retour detailDemande error ', detailDemande);
                    toast("Erreur lors de la récupération mail. Auncun mail n'a  été trouvé avec l'id " + id, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        type: toast.TYPE.ERROR,
                        autoClose: 5000,
                    });
                    //history.push('/mails');
                } else {
                    setDemande(detailDemande.data.reponse);
                    console.log('DETAIL MAIL EN ATTENTE', detailDemande.data.reponse);
                }
            })();
        } catch (error) {
            console.log('catch error', error);
            toast('Erreur lors de la récupération mail du à une erreur de traitement  ', {
                position: toast.POSITION.BOTTOM_RIGHT,
                type: toast.TYPE.ERROR,
                autoClose: 5000,
            });
            history.push('/mails');
        }
    }, []);
    return (
        <>
            <Grid container spacing={2} className="container-demandes">
                <Grid item xs={12} >
                    <h1 className='titre-h1'> <span onClick={(e) => history.push('/mails')} className='back-icon'>Demande non traitée </span> {' > '} Détail de la demande </h1>
                </Grid>
                {/* <Grid item xs={12} lg={2}>
                    <Button onClick={(e) => history.push('/mails')} variant="outlined" color="primary" fullWidth>
                        Retourner aux mails en attentes
                    </Button>
                </Grid> */}
            </Grid>
            {demande && (
                <Grid container spacing={1}>
                    <Grid item xs={12} className="mail-objet">
                        <h3>{demande.objet}</h3>
                    </Grid>
                    <Grid item xs={12} className="mail-expedition">
                        <p className="expediteur">De: {demande.expediteur}</p>
                        <p className="date">Le: {date_demande}</p>
                    </Grid>
                    {destinataires && (
                        <Grid item xs={12} id="mail-destinataires">
                            <p>
                                A :{' '}
                                {destinataires.map((destianataire) => {
                                    if (destianataire.trim() === '') return;
                                    return <span className="mail-bull-user"> {destianataire} </span>;
                                })}{' '}
                            </p>
                        </Grid>
                    )}

                    <Grid item xs={12} id="mail-corps">
                        <p>{demande.corps}</p>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default MailDetail;
