import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextField, Autocomplete, LinearProgress } from '@mui/material';

const ComboApi = ({ disabled, id, options, setOption, label, value, setValue, inputValue, setInputValue, useLoader = false, variant = 'filled', size = 'medium', funcApi = () => {}, useApi = false, argumentsApi = null }) => {
    const [loader, setLoader] = useState(false);
    const [myTimeout, setMyTimeout] = useState(0);
    const delay = 500;

    return (
        <>
            <Autocomplete
               
                disabled={disabled}
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);

                    if (!useApi) return;
                    if (newInputValue == '') return;
                    clearTimeout(myTimeout);
                    setMyTimeout(
                        setTimeout(function () {
                            console.log('TEST ??? = ', newInputValue);
                            setLoader(true);
                            funcApi(...argumentsApi, newInputValue).then((response) => {
                                console.log('response funcApi', response);
                                response.data.reponse = !response.data.reponse ? [] : response.data.reponse;
                                setOption(response.data.reponse);
                                setLoader(false);
                            });
                        }, delay)
                    );
                }}
                id={id}
                options={options}
                // style={{ width: 300 }}
                fullWidth
                renderInput={(params) => <TextField {...params} label={label} variant={variant} size={size} />}
            />
            {useLoader && loader && <LinearProgress />}
        </>
    );
};

export default ComboApi;
