import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoaderVisible, setLoaderHidden } from '../../redux/actions/actionsApp';
import { loginRequest, clearMessages } from '../../redux/actions/actionsAuth';
import { LogoCompany, MentionCorporate, MaskLoader } from '../../components';
import { useForm } from 'react-hook-form';

import { TextField, Button, Container, InputAdornment, IconButton, Alert, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { push } from 'connected-react-router';
import authService from '../../config/utils/authService';
import axios from 'axios';
import { API_KEY, API_MOT_DE_PASSE_OUBLIE } from '../../config/config';

const useStyles = makeStyles({
    paper: {
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: '10px',
        // backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: '10px',
    },
    submit: {
        margin: '10px',
    },
});

/**
 * Page de connexion pour authentification de l'utilisateur avec ses identifiants : username / password
 * En cas d'authentification correcte, un token est conservé dans le local storage et dans le
 *  store et permettra de s'authentifier avec les points d'entrée de l'API
 */
const Login = () => {
    const dispatch = useDispatch();

    const classes = useStyles();

    const loading = useSelector((state) => state.modAuth.loading);
    const displayLoader = useSelector((state) => state.app.displayLoader);
    const success = useSelector((state) => state.modAuth.success);
    const error = useSelector((state) => state.modAuth.error);
    const account = useSelector((state) => state.modAuth.account);

    // Gestion mot de passe passe oublié
    const [txtMdpOublie, settxtMdpOublie] = useState('Mot de passe oublié');
    const [bAfficheMdpOublie, setbAfficheMdpOublie] = useState(true);
    const [etatRequete, setetatRequete] = useState('');

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        console.log('bAfficheMdpOublie', bAfficheMdpOublie);
        if (bAfficheMdpOublie) {
            dispatch(loginRequest(data.username, data.password));
        } else {
            console.log('data', data);
            await motDepasseOubliee(data.username);
        }
        //dispatch(push('home'));
    };

    const [passwordVisible, setPasswordVisible] = useState(false);

    const handleTogglePasswordVisibility = (event) => {
        event.preventDefault();
        setPasswordVisible(!passwordVisible);
    };

    useEffect(() => {
        if (bAfficheMdpOublie) {
            settxtMdpOublie('Mot de passe oublié');
        } else {
            settxtMdpOublie('Se connecter');
        }
        setetatRequete('');
    }, [bAfficheMdpOublie]);

    useEffect(() => {
        if (account) push('home');

        return function cleanUp() {
            // !!!Reset message while unmounting form
            dispatch(clearMessages());
        };
    }, []);

    useEffect(() => {
        if (success) {
            dispatch(push('home'));
        }
    }, [success]);

    useEffect(() => {
        if (loading) {
            dispatch(setLoaderVisible());
        } else {
            dispatch(setLoaderHidden());
        }
    }, [loading]);

    const motDepasseOubliee = async (email) => {
        dispatch(setLoaderVisible());
        const res = await axios({
            method: 'get',
            url: `${API_MOT_DE_PASSE_OUBLIE}`,
            headers: {
                apiclef: API_KEY,
                email: email,
            },
        });
        setetatRequete(res.data.reponse_message);
        dispatch(setLoaderHidden());
    };

    return (
        <React.Fragment>
            <div className="wrapper-login">
                <div className="container">
                    <h1>Hermess</h1>

                    <Container component="main" maxWidth="xs">
                        <div className={classes.paper}>
                            <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                                <TextField
                                    error={errors.username}
                                    className={classes.textField}
                                    variant="filled"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label={bAfficheMdpOublie ? 'Identifiant' : 'Email'}
                                    name="username"
                                    helperText={errors.username && 'Ce champ est obligatoire'}
                                    autoFocus
                                    {...register('username', {
                                        required: true,
                                    })}
                                />
                                {bAfficheMdpOublie && (
                                    <TextField
                                        error={errors.password}
                                        variant="filled"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Mot de passe"
                                        type={!passwordVisible ? 'password' : 'text'}
                                        autoComplete="current-password"
                                        helperText={errors.password && 'Ce champ est obligatoire'}
                                        {...register('password', {
                                            required: true,
                                        })}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={(e) => handleTogglePasswordVisibility(e)}>{passwordVisible ? <Visibility /> : <VisibilityOff />}</IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}

                                <Link className="mdpOublie" onClick={(e) => setbAfficheMdpOublie(!bAfficheMdpOublie)}>
                                    {txtMdpOublie}
                                </Link>
                                {bAfficheMdpOublie ? (
                                    <Button
                                        type="submit"
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        className={classes.submit}
                                        style={{
                                            backgroundColor: '#2d3436',
                                            color: '#FFF',
                                            fontWeight: 700,
                                            marginTop: '5px',
                                        }}
                                    >
                                        Connexion
                                    </Button>
                                ) : (
                                    <Button
                                        type="submit"
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        className={classes.submit}
                                        style={{
                                            backgroundColor: '#2d3436',
                                            color: '#FFF',
                                            fontWeight: 700,
                                            marginTop: '5px',
                                        }}
                                    >
                                        Envoyer un mail
                                    </Button>
                                )}

                                {error && <Alert severity="error">{error}</Alert>}
                                {etatRequete && <Alert severity={'success'}>{etatRequete}</Alert>}
                            </form>
                        </div>
                    </Container>
                </div>

                <ul className="bg-bubbles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
            <MaskLoader visible={displayLoader} />
            <LogoCompany />
            <MentionCorporate />
        </React.Fragment>
    );
};

export default Login;
