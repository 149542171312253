import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography, Avatar, Link, FormControl, IconButton, Button, InputLabel, InputAdornment, Input } from '@mui/material';
import IconLock from '../../assets/images/svg/key.svg';
import EmailIcon from '../../assets/images/svg/email.svg';
import USER_BOY_SVG from '../../assets//images/svg/user-boy.svg' //'../../../assets/images/svg/user-boy.svg';
import USER_GIRL_SVG from '../../assets/images/svg/user-girl.svg';
import { LocalPhoneOutlined as Telephone, EmailOutlined as Mail, Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { API_GET_PRODUIT, API_KEY, API_POST_MAIL, API_POST_PASSWORD } from '../../config/config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setLoaderHidden, setLoaderVisible } from '../../redux/actions/actionsApp';
import { appelApiGet } from '../../config/utils/apiFunction';
import { useHistory } from 'react-router-dom';

const FormMdifInfo = ({ typeInput, setdisplayChamps }) => {
    const account = useSelector((state) => state.modAuth.account);
    const showPwd = (type) => {
        if (type == 'email') return true;
        if (values1.showPWD === true) return true;
        return false;
    };

    const handleClickShowPassword = () => {
        setValues1({
            ...values1,
            showPWD: !values1.showPWD,
        });
    };

    const showPwd2 = (type) => {
        if (type == 'email') return true;
        if (values2.showPWD === true) return true;
        return false;
    };

    const handleClickShowPassword2 = () => {
        setValues2({
            ...values2,
            showPWD: !values2.showPWD,
        });
    };

    const [values1, setValues1] = useState({ value: '', showPWD: false });
    const [values2, setValues2] = useState({ value: '', showPWD: false });
    const [erreur, setErrur] = useState();

    const resetPwd = async () => {
        if (values1.value !== values2.value) {
            setErrur('Les valeurs ne correspondent pas.');
            return;
        }

        const ep = typeInput == 'password' ? API_POST_PASSWORD(account.code_acces.trim()) : API_POST_MAIL(account.code_acces.trim());
        const data = typeInput != 'password' ? { mail: values2.value } : { password: values2.value };
        try {
            const majData = await axios({
                method: 'POST',
                url: ep,
                headers: {
                    apiclef: API_KEY,
                    authorization: 'Bearer ' + localStorage.getItem('token'),
                },
                data,
            });

            if (!majData.data.ok) {
                setErrur('Erreur lors de la modifications des données');
                return;
            }
            toast.success('Changement des informations ok ', {
                position: toast.POSITION.BOTTOM_RIGHT,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
            });

            if (typeInput != 'password') {
                console.log('dispatch');
            }

            setErrur();
            setdisplayChamps(false);
        } catch (error) {
            console.log('error', error);
        }
    };
    return (
        <>
            <Grid container className="container-form">
                <Grid item sm={10} display="flex" flexDirection="column">
                    <FormControl variant="standard" className="champs">
                        <InputLabel htmlFor="standard-adornment-password">{typeInput == 'password' ? 'Mot de passe' : 'Email'}</InputLabel>
                        <Input
                            id="1"
                            type={showPwd(typeInput) ? 'email' : 'password'}
                            value={values1.value}
                            onChange={(e) => setValues1({ ...values1, value: e.target.value })}
                            endAdornment={
                                typeInput == 'password' && (
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={(e) => e.preventDefault()}>
                                            {values1.showPWD ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }
                        />
                    </FormControl>
                    <FormControl variant="standard" className="champs">
                        <InputLabel htmlFor="standard-adornment-password"> {typeInput == 'password' ? 'Confirmer le mot de passe' : "Confirmer l'Email"}</InputLabel>
                        <Input
                            id="1"
                            type={showPwd2(typeInput) ? 'email' : 'password'}
                            value={values2.value}
                            onChange={(e) => setValues2({ ...values2, value: e.target.value })}
                            endAdornment={
                                typeInput == 'password' && (
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword2} onMouseDown={(e) => e.preventDefault()}>
                                            {values2.showPWD ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }
                        />
                    </FormControl>

                    <Button color="error" variant="outlined" className="champs" onClick={(e) => resetPwd(e)}>
                        Valider
                    </Button>
                    {erreur && (
                        <Typography color="red" className="poste">
                            {erreur}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

const FormMajInfoPerso = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    

    //
    const account = useSelector((state) => state.modAuth.account);
    console.log('account', account);
    let picture = account.titre.includes('Madame') ? USER_GIRL_SVG : USER_BOY_SVG;


    /**state */
    const [displayFormPwd, setdisplayFormPwd] = useState(false);
    const [displayFormMail, setdisplayFormMail] = useState(false);
    const [tabproduit, settabproduit] = useState([]);

    useEffect(() => {
        dispatch(setLoaderVisible());

        (async () => {
            try {
                /******************************* On récupére les produits du client********************** */
                let url = `${API_GET_PRODUIT + (account.isTec ? '' : '?codeAcces=' + account.code_acces)}`;
                let reponse = await appelApiGet(url);
                settabproduit(reponse.data.reponse);
            } catch (error) {
                console.log('error useeffect init  catch', error);
            }
        })();

        dispatch(setLoaderHidden());
    }, []);

    return (
        <>
            <h1>Mes Informations personnelles</h1>
            {account.person && (
                <Grid container spacing={4}>
                    {/**  Card information personnelle*/}
                    <Grid item xs={12} lg={3} marginTop={5}>
                        <div className="card-container">
                            <div className="card-info">
                                <Avatar sx={{ width: 100, height: 100 }} className="avatar" src={picture}  >
                                    
                                </Avatar>
                                <div className="identifiant">
                                    <Typography className="nom">{account.person.trim()}</Typography>
                                    <Typography className="societe">{account.lib.trim()}</Typography>
                                    <Typography className="poste">{` ${account.fonctions.trim() > 20 ? account.fonctions.trim().substr(0, 20) + ' ...' : account.fonctions.trim()} `}</Typography> 
                                </div>

                                <div className="info-plus">
                                    <Typography className="email">
                                        <Mail fontSize="small" className="icon" /> {account.mail.trim()}
                                    </Typography>
                                    <Typography className="numero">
                                        <Telephone fontSize="small" className="icon" /> {account.tel.trim()}
                                    </Typography>
                                </div>
                                <hr></hr>

                                <div className="info-plus">
                                    <p className='titre-produit'> Logiciels utilisés :</p>
                                    {tabproduit && tabproduit.map(produit => <p className='produit'>- <span className='span-produit'>{produit.lib_logiciel.trim().toLowerCase()}</span></p>)}
                                </div>

                                <div className="bottom-card">
                                    <hr></hr>
                                    <Link onClick={e => history.push('/logout')} className="deconnecte">Se déconnecter</Link>
                                </div>
                            </div>
                        </div>
                    </Grid>

                    {/**  Card mot de passe*/}
                    <Grid item xs={12} lg={3} marginTop={5}>
                        <div className="card-container">
                            <div className="card-secodaire mdp">
                                <Typography className="titre">Mot de passe</Typography>
                                {displayFormPwd ? (
                                    <FormMdifInfo typeInput="password" setdisplayChamps={setdisplayFormPwd} />
                                ) : (
                                    <div>
                                        <img src={IconLock} />
                                        <p className="description"> Renforcez votre mot de passe ou modifiez-le si quelqu'un le connaît. </p>
                                        <Link className="btn-displayForm" onClick={() => setdisplayFormPwd(!displayFormPwd)}>
                                            Changer votre mot de passe {'>'}
                                        </Link>
                                    </div>
                                )}
                            </div>
                            <div className="card-secodaire email">
                                <Typography className="titre">Email</Typography>
                                {displayFormMail ? (
                                    <FormMdifInfo typeInput="email" setdisplayChamps={setdisplayFormMail} />
                                ) : (
                                    <div>
                                        <img src={EmailIcon} />
                                        <p className="description"> Conservez votre email à jour. </p>
                                        <Link className="btn-displayForm" onClick={() => setdisplayFormMail(!displayFormMail)}>
                                            Changer votre Email {'>'}
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default FormMajInfoPerso;

const majMail = async (idUser, mail) => {
    return await axios({
        method: 'PUT',
        url: API_POST_MAIL(idUser),
        headers: {
            apiclef: API_KEY,
            authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        data: { mail },
    });
};

const majPWD = async (idUser, password) => {
    return await axios({
        method: 'PUT',
        url: API_POST_PASSWORD(idUser),
        headers: {
            apiclef: API_KEY,
            authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        data: { password },
    });
};
