import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, Grid, Typography } from '@mui/material';
import axios from 'axios';
import {  API_KEY, API_GET_CLIENT_SOCIETE, API_PATCH_CONTACT_CLIENT, API_GET_GED_NEWUSER_LISTE, API_GET_GED_NEWUSER_STREAM } from '../../config/config';
import { toast } from 'react-toastify';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { appelApiGedGet, appelApiGet } from '../../config/utils/apiFunction';
import { useDispatch, useSelector } from 'react-redux';
import ComboApi from '../Demandes/component/comboApi';
import { setLoaderHidden, setLoaderVisible } from '../../redux/actions/actionsApp';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { API_GET_PRODUIT } from '../../config/config';
import { useHistory } from 'react-router-dom';

const columns = [
    { id: 'nom', label: 'Nom', minWidth: 100 },
    { id: 'prenom', label: 'Prénom', minWidth: 100 },
    {
        id: 'fonctions',
        label: 'Fonction',
        minWidth: 100,
        align: 'right',
        format: "0",
    },
    {
        label: 'Mail',
        id: 'mail',
        minWidth: 100,
        align: 'right',
        format: "0",
    },
    {
        label: 'Téléphone',
        id: 'tel',
        minWidth: 100,
        align: 'right',
        format: "0"
    },
    {
        label: 'Portable',
        id: 'tel_port',
        minWidth: 100,
        align: 'right',
        format: "0"
    },
    {
        label: 'Autre Téléphone',
        id: 'tel_autre',
        minWidth: 100,
        align: 'right',
        format: "0"
    },
    {
        label: 'Club Invité',
        id: 'Club invite',
        minWidth: 100,
        align: 'right',
        format: "1"
    },
    {
        id: 'Recoit_cc_mail_auto',
        label: 'Copie mails',
        minWidth: 100,
        align: 'right',
        format: "1"
    },
    {
        label: 'Directeur',
        id: 'Directeur',
        minWidth: 100,
        align: 'right',
        format: "1"
    },
    {
        id: 'Responsable',
        label: 'Responsable',
        minWidth: 100,
        align: 'right',
        format: "1"
    },
    {
        label: 'Non user',
        id: 'NON UTILISATEUR',
        minWidth: 100,
        align: 'right',
        format: "1"
    },
    {
        id: 'NO MAILING',
        label: 'No mailing',
        minWidth: 100,
        align: 'right',
        format: "1"
    },
    {
        id: 'Vide',
        label: '',
        minWidth: 100,
        align: 'right',
        format: "0"
    },

];





const GestionContact = () => {

    const dispatch = useDispatch();
    const account = useSelector((state) => state.modAuth.account);
    const history  = useHistory();

    // const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    //  Société
    const [societe, setsociete] = useState( account.societe[0].w_affich.trim());
    const [inputsociete, setInputsociete] = useState('');
    const [tabsociete, setTabsociete] = useState(account.societe);

    //tab client
    const [tabClient, setTabClient] = useState([]);
    const [tabFiltreUser, setTabFiltreUser] = useState([]);

    //Modal
    const [open, setOpen] = useState(false);
    const [nomEncours, setNomEncours] = useState("");
    const [prenomEncours, setPreNomEncours] = useState("");
    const [telEncours, setTelEncours] = useState("");
    const [FonctionEncours, setFonctionEncours] = useState("");
    const [protableEnCours, setPortablenCours] = useState("");
    const [autreprotableEnCours, setAutrePortableEnCours] = useState("");
    const [rowEncours, setRowEncours] = useState();


    // Champs à modifier
    const [tel, setTel] = useState('');
    const [telPort, setTelPort] = useState('')
    const [telAutre, setTelAutre] = useState('')
    const [fonction, setFonction] = useState('')

    // Téléchargement fichier nouveau client
    const [urlStreamDoc, seturlStreamDoc] = useState("");
    
    //Combo 
    const [value, setValue] = useState('');
    const [inputvalue, setinputvalue] = useState('');


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const displayDoc = () => {
        //window.location.href = urlStreamDoc
        window.open(urlStreamDoc)
    }


    const modalOpen = (row ) => {

        console.log("param func 1 : ", row,)
        setOpen(true)
        setNomEncours(row.nom.trim())
        setPreNomEncours(row.prenom)
        setTelEncours(row.tel[0].trim())
        setFonctionEncours(row.fonctions.trim())
        setPortablenCours(row.tel_port.trim())
        setAutrePortableEnCours(row.tel_autre.trim())
        setRowEncours(row)
    }


    // Useeffect Societe 
    useEffect(() => {
        (async () => {
            dispatch(setLoaderVisible());
            try {
                const reponse = await appelApiGet(API_GET_CLIENT_SOCIETE(tabsociete.find(soc => soc.w_affich.trim() == societe.trim()).code) );
                console.log('reponse api client', reponse.data.reponse);
                setTabClient(reponse.data.reponse.tabPersonne)
                setTabFiltreUser(reponse.data.reponse.tabFiltreUser)

            } catch (error) {
                console.log('erreur catch', error);
                dispatch(setLoaderHidden());
            }
            dispatch(setLoaderHidden());
        })();
    }, [societe])


    // Useeffect lien téléchargement 
    useEffect(() => {
        (async () => {
            dispatch(setLoaderVisible());
            try {
                let reponse = await appelApiGet(API_GET_PRODUIT + "?codeAcces=" + account.code_acces.trim());
                console.log('reponse api produit', reponse.data.reponse);
                // On vérifie si le client connecté à HE (hebergement) dans ses produits
                const tabProduitHe = reponse.data.reponse.filter(p => p.c_logiciel.trim() === "HE") ;
                console.log("reponse", tabProduitHe);
                let bHE = tabProduitHe.length > 0 ? true : false 
                let workspaceDoc = bHE ? 'nouveau,utilisateur,HE' : 'nouveau,utilisateur,autre'

                //On recupère le document dans la ged pour filtrer
                let url = `${API_GET_GED_NEWUSER_LISTE(workspaceDoc)}`;
                console.log('reponse api stream', reponse.data.reponse);
                reponse = await appelApiGedGet(url);
                console.log('reponse api stream', reponse.data.reponse);
                const listeDoc = reponse.data.reponse
                if (reponse.data.existe){ 
                    const _id = listeDoc[0]._id
                    //Lien Du doc à télécharger 
                    const link = await API_GET_GED_NEWUSER_STREAM(_id)
                    console.log("link", link);
                    seturlStreamDoc(link)
                }


            } catch (error) {
                console.log('erreur catch', error);
                dispatch(setLoaderHidden());
            }
            dispatch(setLoaderHidden());
        })();
    }, [])
    


    /**************************** Submit Form ********************************/
    const handleSendForm = async (e, rowEncours) => {
        e.preventDefault();
        dispatch(setLoaderVisible());
        try {
            /** Vérification des informations du formulaire */
            const body = {
                "infoMaj" : {
                    "fonctions" : (fonction && fonction != FonctionEncours  ) ? fonction : FonctionEncours, 
                    "tel" :  (tel && tel != telEncours  ) ? tel : telEncours, 
                    "tel_autre" : (telAutre && autreprotableEnCours != telAutre  ) ? telAutre : autreprotableEnCours ,  
                    "tel_port" : (telPort && protableEnCours != telPort  ) ? telPort : protableEnCours , 
                }

            };


            console.log('body', body);
            console.log(' rowEncours ', rowEncours);
           const patchMajClient = await axios({
                method: 'PATCH',
                url: API_PATCH_CONTACT_CLIENT(tabsociete.find(soc => soc.w_affich.trim() == societe.trim()).code.trim(), rowEncours.person.trim()),
                headers: {
                    apiclef: API_KEY,
                    authorization: 'Bearer ' + localStorage.getItem('token'),
                },
                data: body,
            });
            console.log('retour Patch demande', patchMajClient.data);
            if (!patchMajClient.data.ok) {
                console.log('retour PUT demande error');
                toast.error("Problème lors de la modification des informations ", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000,
                });
            } else {
                //maj des infos du tableau sanns actaliser
                const newTabUser = tabClient.map(client => {
                    if (client.person.trim() === rowEncours.person){
                        client.fonctions = fonction
                        client.tel[0] = tel
                        client.tel_autre = telAutre
                        client.tel_port = telPort
                    }
                    return client

                    
                })
                setTabClient(newTabUser)
                setOpen(false)
                toast.success('Votre demande a été effectuée avec succès', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000,
                });

                //Combo renseigné  on redirige vers  la creation d'une demande
                if (!value) {
                    window.location.reload()
                }else {

                    history.push('/nouvelleDemande/L/'+ nomEncours.trim() +"/" + prenomEncours.trim() + '/'+ value)
                }
            } 
        } catch (error) {
            console.error('error', error);
            toast.error('Error lors de la création de la demande', {
                position: toast.POSITION.BOTTOM_RIGHT,
                type: toast.TYPE.ERROR,
                autoClose: 5000,
            });
        }
        dispatch(setLoaderHidden());
    };


    return (

        <div>
            <h1>Gestion des contacts</h1>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={10} >
                    <ComboApi
                        disabled={account.societe.length > 1 ? false : true}
                        id="3"
                        size="small"
                        variant="outlined"
                        options={tabsociete.map((soc) => soc.w_affich)}
                        setOption={setTabsociete}
                        label="Société"
                        value={societe}
                        setValue={setsociete}
                        inputValue={inputsociete}
                        setInputValue={setInputsociete}
                        useApi={false}

                    />
                </Grid>
                <Grid item xs={12} lg={2} width={"100%"}>
                    <Button variant="contained" color="primary" onClick={e => displayDoc()}>Ajouter un collaborateur</Button>
                </Grid>
                {tabClient.length > 0 && tabFiltreUser.length > 0 && (<Grid item xs={12} >
                    <Paper className=" ">
                        <TableContainer className="">
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tabClient.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                        let filtreEnCours = tabFiltreUser.find(filtreUser => filtreUser.person.trim() == row.person.trim())
                                        let userFitler = {...row, ...filtreEnCours.filtres, idTab : i}
                                        console.log('row final', userFitler );
                                        return (
                                            <TableRow className='rowTab' hover role="checkbox" tabIndex={-1} key={i} onClick={() => modalOpen(userFitler)}>
                                                {columns.map((column) => {
 
                                                    const value = userFitler[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.format == "1" ?
                                                                <Checkbox disabled checked={value == "O" || value == "1"? true : false} />
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 100]}
                            component="div"
                            count={tabClient.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage="Lignes par page"
                        />
                    </Paper>
                </Grid>)}
            </Grid>

            <div>

                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="Mise à jour des informations de"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Typography marginBottom={5} id="modal-modal-title" variant="h6" component="h2">
                            Mise à jour des informations de <span className='nomPrenom'> {nomEncours} {prenomEncours} </span>
                        </Typography>
                        <Grid marginBottom={3} item xs={12}>
                            <TextField  defaultValue={FonctionEncours} value={fonction} onChange={(e) => setFonction(e.target.value)} minRows={5} fullWidth  size="small" variant="outlined" label="Fonction"></TextField>
                        </Grid>
                        <Grid marginBottom={3} item xs={12}>
                            <TextField defaultValue={telEncours}  value={tel} onChange={(e) => setTel(e.target.value)} minRows={5} fullWidth  size="small" variant="outlined" label="Téléphone"></TextField>
                        </Grid>
                        <Grid marginBottom={3} item xs={12}>
                            <TextField defaultValue={protableEnCours}  value={telPort} onChange={(e) => setTelPort(e.target.value)} minRows={5} fullWidth  size="small" variant="outlined" label="Portable"></TextField>
                        </Grid>
                        <Grid marginBottom={3} item xs={12} >
                            <TextField defaultValue={autreprotableEnCours}  value={telAutre} onChange={(e) => setTelAutre(e.target.value)} minRows={5} fullWidth size="small" variant="outlined" label="Autre téléphone"></TextField>
                        </Grid>
                        <Grid marginBottom={3} item xs={12} >
                            <Autocomplete id="combo" options={optionsCombo} value={value} onChange={(e, newValue) => setValue(newValue)} inputValue={inputvalue} onInputChange={(e, newValue) => setinputvalue(newValue) } fullWidth renderInput={(params) => <TextField {...params}  variant={"filled"} size={"medium"} />} />
                        </Grid>

                        <Grid item xs={12} lg={2} width={"100%"}>
                            <Button variant="contained" color="primary" onClick={(e) => handleSendForm(e, rowEncours)} >Modifier</Button>
                        </Grid>
                    </Box>
                </Modal>
            </div>

        </div>
    )

}


const style = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "auto",
    height: "auto",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    
  };

  const optionsCombo = ["Ne fait plus partie de la société", "N’est pas utilisateur de l’application ", "Autre modification "]; 




export default GestionContact