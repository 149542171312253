import React, { useState } from 'react';
import ForwardIcon from '@mui/icons-material/Forward';
import GamesIcon from '@mui/icons-material/Games';
import StopIcon from '@mui/icons-material/Stop';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

function InformationMessage({ children, className = "", legend = null, type = "information", borderDisplay = true, style = {} }) {

    let iconMessage = useState(<InfoIcon className="highlight" />);

    className += ` ${type}`;

    if (!borderDisplay) className += ' no-border';

    const initType = () => {
        switch (type) {
            case "control":
                iconMessage = <GamesIcon className="highlight" />;
                className += ' control';
                break;
            case "warning":
                iconMessage = <WarningIcon className="highlight" />;
                className += ' warning';
                break;
            case "forward":
                iconMessage = <ForwardIcon className="highlight" />;
                className += ' forward';
                break;
            case "start":
                iconMessage = <PlayCircleFilledWhiteIcon className="highlight" />;
                className += ' start';
                break;
            case "stop":
                iconMessage = <StopIcon className="highlight" />;
                className += ' stop';
                break;
            default:
                iconMessage = <InfoIcon className="highlight" />;
                break;
        }
    };

    initType();


    return (
        <React.Fragment>
            {legend &&
                <div className={`panel-message-legend ${className}`}>{legend}</div>
            }
            <div className={`panel-message panel-message-icon ${className}`} style={style}>
                {iconMessage}
                {children}
            </div>
        </React.Fragment>
    )
}

export default InformationMessage;
