import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Header from './Header';
import ToolLink from './ToolLink';
import Avatar from './Avatar';
import Logo from '../../../assets/images/logo.png';
import { useSelector } from 'react-redux';
import { toggleSidebarVisibility } from '../../../redux/actions/actionsApp';
import { Utils } from '../../../config/utils/index';
import { VERSION } from './../../../config/config';
import { LogoCompany } from '../../../components';

import { ENV } from './../../../config/config';
import PropTypes from 'prop-types';

const Sidebar = ({ id, data = [] }) => {

    const account = useSelector(state => state.modAuth.account);
    const sidebarOpen = useSelector(state => state.app.sidebar.open);
    const [menus, setMenus] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        setMenus(data);
    }, []);

    const displayMenu = (e) => {
        e.currentTarget.classList.toggle('expanded');
        e.currentTarget.nextSibling.classList.toggle('expanded');
    }

    return (
        <nav id={id} className={`sidebar no-print ${sidebarOpen ? '' : 'closed'}`}>
            <nav className="nav-mobile-top">
                <div id="nav-icon-mobile" className="hamburger-menu" onClick={() => dispatch(toggleSidebarVisibility())}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </nav>

            <div className="nav-fixed">

                <LogoCompany classExtended="logo-info" />

                {ENV === 'DEV' &&
                    <div className="debug-info">{ENV} : v{VERSION}</div>
                }

                <ul className="bg-bubbles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>

                <div id="nav-icon" className="hamburger-menu" onClick={() => dispatch(toggleSidebarVisibility())}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                <Avatar account={account} />

                <ul className="container-menu custom-scrollbar">
                    {menus.map((menu, i) => {
                        if (menu.group.rights === 1 || !menu.group.rights) {

                            return (

                                <React.Fragment key={i}>
                                    {menu.group.displayHeader === 1 &&
                                        <Header key={i} title={menu.group.title} shortTitle={menu.group.shortTitle} handleClick={displayMenu} />
                                    }
                                    <ul className="header-content expanded">
                                        {menu.links.map((link, i) => {
                                            if (link.rights === 1 || !link.rights) {
                                                return (
                                                    <ToolLink key={i} title={Utils.capitalize(link.title)} route={link.route} icon={link.icon} />
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </ul>
                                </React.Fragment>

                            )
                        } else {
                            return null;
                        }
                    })}
                </ul>
            </div>

            <div className="logo">
                <img src={Logo} alt="logo" />
            </div>

            {/* <div className="bg">
                <img src={BG} alt="bg" />
            </div> */}

        </nav >
    )
};

Sidebar.propTypes = {
    id: PropTypes.string // identifiant navbar
};

export default Sidebar;