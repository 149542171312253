import React from 'react';

const Error404 = () => {
    return (
        <React.Fragment>
            <div className="container-error">
                <h1 className="message">ERROR 404</h1>
            </div>
        </React.Fragment>
    )
}

export default Error404;
