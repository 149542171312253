import { combineReducers } from 'redux';
import app from './modules/app';
import modAuth from './modules/auth';
import demandes from './modules/demandes';
import mails from './modules/mailsReducer';

import { connectRouter } from 'connected-react-router';

const reducers = (history) =>
    combineReducers({
        router: connectRouter(history),
        app,
        modAuth,
        demandes,
        mails,
    });

export default reducers;
