import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useSelector, useDispatch } from 'react-redux';
import { toggleDrawerVisibility } from '../../../redux/actions/actionsApp';

function DrawerRightContent() {

    const dispatch = useDispatch();
    const drawerWidth = useSelector(state => state.app.drawers.right.width);

    return (
        <div className="container-drawer drawer-right" style={{ width: drawerWidth }}>
            <IconButton aria-label="delete" className="btn-close-drawer" onClick={() => dispatch(toggleDrawerVisibility('right', false))}>
                <ArrowForwardIcon fontSize="large" />
            </IconButton>
        </div>
    )
}

export default DrawerRightContent;
