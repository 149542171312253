import React, { useEffect, useState } from 'react';
import DemandesSearch from './component/demandes/recherche';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { setLoaderVisible, setLoaderHidden } from '../../redux/actions/actionsApp';
import { toast } from 'react-toastify';
import DemandeItem from './component/demandes/demandeItem';
import 'react-toastify/dist/ReactToastify.css';
import MyPagination from './component/pagination';
import { useHistory } from 'react-router-dom';
import { demandeUpdatePagination } from '../../redux/actions/actionDemande';

const Demandes = () => {
    /**  Dispatch **/
    const dispatch = useDispatch();

    /** Init fonction pour aller sur un autre composant */
    const history = useHistory();

    /**  State locale **/
    const [displayResearch, setDisplayResearch] = useState(true);
    /** demandes en cours  pour la pagination  */
    const [demandes, setDemandes] = useState([]);
    /** Pour bloquer l'affichage multiple de toast */
    const [toastDisplay, settoastDisplay] = useState(true);

    /**  Selector **/
    const account = useSelector((state) => state.modAuth.account);
    const loadingDemandes = useSelector((state) => state.demandes.loading);
    const errorDemandes = useSelector((state) => state.demandes.error);
    const demandesStore = useSelector((state) => state.demandes.demandes);
    const pageEncours = useSelector((state) => state.demandes.pagination);

    /**  Use effect affichage notif error **/
    useEffect(() => {
        if (!toastDisplay) return;

        if (errorDemandes) {
            settoastDisplay(false);
            console.log('useeffect error demande', errorDemandes);
            toast.error('Aucune demande récupérée.', {
                position: toast.POSITION.BOTTOM_RIGHT,
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                onClose: () => settoastDisplay(true),
            });
        }
    }, [errorDemandes]);

    /**  Use effect affichage loader **/
    useEffect(() => {
        if (loadingDemandes) {
            dispatch(setLoaderVisible());
        } else {
            dispatch(setLoaderHidden());
        }
    }, [loadingDemandes]);

    const updatePage = (page) => {
        dispatch(demandeUpdatePagination(page));
    };


    return (
        <React.Fragment>
            <Grid container className="container-demandes">
                <Grid item xs={12}>
                    <h1>Mes demandes</h1>
                </Grid>
                {/* <Grid item xs={12} lg={2}>
                    <Button onClick={(e) => history.push('/mails')} variant="outlined" color="primary">
                        Voir mes mails en attente
                    </Button>
                </Grid> */}
            </Grid>

            <Grid container>
                {/* <Grid item xs={6}>
                    <Button startIcon={displayResearch ? <ArrowDropDownIcon /> : <ArrowRightIcon />} onClick={() => setDisplayResearch(!displayResearch)} variant="contained" color="primary">
                        Critère de recherche
                    </Button>
                </Grid> */}
                {displayResearch && <DemandesSearch />}
            </Grid>
            {demandesStore && (
                <Grid container>
                    {/* Pagination */}

                    <MyPagination totalDemande={demandesStore.length} setDemandes={setDemandes} demandesStore={demandesStore} demandes={demandes} pageEncours={pageEncours} setpageEncours={updatePage}></MyPagination>

                    {/* Liste des demandes */}
                    {demandes && (
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {demandes.map((demande, index) => {
                                    return (
                                        <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                                            <DemandeItem item={demande} /*onItemSelect={handleSelectDemande}*/ userConnect={account} />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            {/* Pagination Suivant */}
                        </Grid>
                    )}
                </Grid>
            )}
        </React.Fragment>
    );
};

export default Demandes;

//
