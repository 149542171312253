import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { toggleSidebarVisibility } from './../../../redux/actions/actionsApp';
import { Tooltip } from '@mui/material';

const ToolLink = ({ title, route, icon }) => {

    const linkClass = `item ${icon}`;
    const dispatch = useDispatch();

    /**
     * UGLY CODE - Fix Activate NavLink is not working...???
     * @param {event} e 
     */
    const handleActiveLink = e => {
        const navLinks = document.querySelectorAll('#scepia-sidebar .header-content a');
        navLinks.forEach(navLink => navLink.classList.remove('active'));
        e.currentTarget.classList.add('active');
        dispatch(toggleSidebarVisibility(false));
    }

    return (
        <NavLink to={route} activeClassName="XYZ" onClick={(e) => handleActiveLink(e)}>
            <Tooltip title={title} placement="right-end">
                <li className={linkClass}><span className="label">{title}</span></li>
            </Tooltip>
        </NavLink>
    )
}

export default ToolLink;
