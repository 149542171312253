export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';

export const TOKEN_LOGIN = 'TOKEN_LOGIN';

export const INIT_MOD_AUTH = 'INIT_MOD_AUTH';

export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export const DECONNECT = 'DECONNECT';
