import * as types from './actionTypes/app';

export const setLoaderVisible = () => ({
    type: types.SET_LOADER_VISIBLE
});

export const setLoaderHidden = () => ({
    type: types.SET_LOADER_HIDDEN
});

export const setModalLogoutVisible = () => ({
    type: types.SET_MODAL_LOGOUT_VISIBLE
});

export const toggleDrawerVisibility = (drawerTarget, open, contentType = '') => ({
    type: types.TOGGLE_DRAWER_VISIBILITY,
    drawerTarget,
    open,
    contentType,
});

export const toggleSidebarVisibility = (open) => ({
    type: types.TOGGLE_SIDEBAR_VISIBILITY,
    open
});
