/// REACT & REDUX
import React, { useEffect, useRef, useState } from 'react';

/// Composant moment
import moment from 'moment';

/// Composant material UI
import { Grid, Button, Typography, Card, CardContent, CardActions } from '@mui/material';
import { ArrowRight as ArrowRightIcon, Notifications as NotificationsIcon } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const DemandeItem = ({ item, userConnect, sizeMaxContent = 100, onItemSelect }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    /**
     * ### variables
     * --------------------------------------------------------------------------------
     */

    // const date_demande = moment(item.dh_demande, 'YYYYMMDDHHmm').format('DD/MM/YYYY HH:mm');
    // const date_reponse = moment(item.dh_reponse, 'YYYYMMDDHHmm').format('DD/MM/YYYY HH:mm');
    // const date_cloture = item.dh_cloture.trim() && item.dh_cloture.trim() !== '' ? moment(item.dh_cloture, 'YYYYMMDDHHmm').format('DD/MM/YYYY HH:mm') : null;

    let tab_split_date = moment(item.dh_demande, 'YYYYMMDDHHmm').format('DD/MM/YYYY HH:mm').split(' ');
    const date_demande = tab_split_date[0] + ' à ' + tab_split_date[1];

    tab_split_date = moment(item.dh_reponse, 'YYYYMMDDHHmm').format('DD/MM/YYYY HH:mm').split(' ');
    const date_reponse = tab_split_date[0] + ' à ' + tab_split_date[1];

    tab_split_date = item.dh_cloture.trim() && item.dh_cloture.trim() !== '' ? moment(item.dh_cloture, 'YYYYMMDDHHmm').format('DD/MM/YYYY HH:mm').split(' ') : [];
    const date_cloture = tab_split_date.length > 0 ? tab_split_date[0] + ' à ' + tab_split_date[1] : null;

    const [bVoirPlus, setbVoirPlus] = useState(false);

    /**
     * ### Fonction
     * --------------------------------------------------------------------------------
     */
    const handleLeanMore = () => onItemSelect(item.ordre, notifDisplay);

    /**
     *
     * @param {string} person
     * @param {string} vuPar
     * @param {string} cloture
     * @returns
     */
    const handleDisplayNotif = (person, vuPar, cloture) => {
        if (cloture.trim() != '') return false;
        const incldue = vuPar.trim().includes(`${person.trim()}`);
        return !incldue;
    };

    /**
     * ### variables
     * --------------------------------------------------------------------------------
     */
    const notifDisplay = handleDisplayNotif(userConnect.person, item.vuPar, item.dh_cloture);
    // console.log('notifDisplay', notifDisplay, item.vuPar);

    /**
     * ### Affichage
     * --------------------------------------------------------------------------------
     */
    return (
        <React.Fragment>
            <Grid container spacing={1} className="ticket">
                <Grid item xs={12}>
                    <Card onMouseOver={() => setbVoirPlus(true)} onMouseOut={() => setbVoirPlus(false)} className="HERMESS_CardDemandes" onClick={(e) => history.push(`/detailDemande/${userConnect.code_acces.trim()}/${item.ordre.trim()}`)}>
                        <CardContent className="">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container wrap="nowrap">
                                                <Grid item xs={12}>
                                                    {' '}
                                                    <Typography color="#457b9d" fontWeight={700} component="span" variant="subtitle1">{`${item.ordre} :  ${item.obj.length > 40 ? item.obj.substr(0, 40) + ' ...' : item.obj} `}</Typography>
                                                    <hr />
                                                </Grid>
                                                <Grid item>
                                                    {notifDisplay && (
                                                        <Grid container justifyContent="flex-end">
                                                            <NotificationsIcon className="HERMESS_NotifIcon" color="secondary" />
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {/* <Typography variant="subtitle2" color="textSecondary" gutterBottom>Demande du: {date_demande}, {!date_cloture ? `dernière reponse le: ${date_reponse}` : `clôturé le: ${date_cloture}`}</Typography> */}
                                            <Typography component="span" variant="subtitle2" color="textSecondary" gutterBottom>
                                                Demande du {date_demande}{' '}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className="echange" component="p">
                                        {item.question.substr(0, sizeMaxContent)}...
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Grid container justifyContent="flex-end" className="bottomCard">
                                <Grid item className=" cloture">
                                    <Typography component="span" variant="subtitle2" color={!date_cloture ? 'textSecondary' : 'secondary'} gutterBottom>
                                        {!date_cloture ? `dernière reponse le ${date_reponse}` : `clôturé le: ${date_cloture}`}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {/* <Button endIcon={<ArrowRightIcon />} onClick={() => handleLeanMore()} color="primary">
                                        Voir plus
                                    </Button> */}
                                    {bVoirPlus && (
                                        <Typography className="voirPlus" color={'primary'}>
                                            VOIR PLUS
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default DemandeItem;
