import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Loader } from '../../components/index';
import authService from '../../config/utils/authService';
import { appDeconnect, clearMessages as clearAuth } from '../../redux/actions/actionsAuth';
import { clearMessages as clearMail } from '../../redux/actions/actionMails';
import { clearMessages as clearDemande } from '../../redux/actions/actionDemande';

const Logout = () => {
    const dispatch = useDispatch();

    // !!!RAZ des data de chaque module
    // dispatch(resetModRequest());

    authService.clearData();

    const [logout, setLogout] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            dispatch(appDeconnect());
            dispatch(clearDemande());
            dispatch(clearMail());
            setLogout(true);
        }, 1000);
    }, []);

    return <React.Fragment>{logout === false ? <Loader /> : <Redirect to="/" />}</React.Fragment>;
};

export default Logout;
