/// REACT & REDUX
import React, { useEffect, useRef, useState } from 'react';

/// Composant moment
import moment from 'moment';

/// Composant material UI
import { Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle, Grid, Button, TextField, Typography, Accordion,
         AccordionSummary, AccordionDetails, AccordionActions, IconButton, Tooltip } from '@mui/material';


import { Cancel as CancelIcon, Send as SendIcon, ArrowDropUp as ArrowDropUpIcon, ExpandMore as ExpandMoreIcon, GetApp as GetAppIcon, Refresh as RefreshIcon, Person as PersonIcon, RecordVoiceOver as RecordVoiceOverIcon, LockClockOutlined, AccessTime, HttpsOutlined } from '@mui/icons-material';

import { API_GET_DETAIl_DEMANDE, API_GET_DOCUMENT, API_KEY, API_PUT_DETAIL_DEMANDE, API_PUT_VUE, API_POST_DEMANDE, API_PUT_DEMANDE_CLOTURE } from '../../../../config/config';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { setLoaderHidden, setLoaderVisible } from '../../../../redux/actions/actionsApp';
import { FilePond, registerPlugin } from 'react-filepond'; // File
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { File } from '../../../../config/utils/file';
import { demandeMajVu } from '../../../../redux/actions/actionDemande';
import TableFichier from '../tableFichier';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import { appelApiGet } from '../../../../config/utils/apiFunction';
import BackIcon from '../../../../assets/images/svg/back-arrow-black.svg';
import { Buffer } from 'buffer';

// Configuragtion des modules importé
toast.configure();
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType);

const DemandeDetail = () => {
    const dipatch = useDispatch();
    const history = useHistory();
    const paramsUrl = useParams();
    const USERTYPE_CLIENT = 0;
    const USERTYPE_EMPLOYE = 1;

    //StateLocal
    const [ordre, setOrdre] = useState(paramsUrl.ordre);
    const [idUser, setIdUsere] = useState(paramsUrl.idUser);
    const [demande, setDemande] = useState();
    const [commentaire, setCommentaire] = useState();
    const [form_filePond, setForm_filePond] = useState([]);
    const [date_cloture, setdate_cloture] = useState('');
    const [date_demande, setdate_demande] = useState('');
    const [date_reponse, setdate_reponse] = useState('');
    const [filesItem, setfilesItem] = useState([]);
    const [dataFile, setdataFile] = useState();
    const [fileName, setfileName] = useState();
    const [load, setLoad] = useState(0);

    // Ouvri fermer dialogue
    const [open, setOpen] = useState(false);

    // Scroll bas e de page
    const finDePage = useRef(null);

    // Selector
    const techniciens = useSelector((state) => state.modAuth.isTec);
    const account = useSelector((state) => state.modAuth.account);
    //const maintenance = useSelector((state) => (!state.demandes ? {} : state.demandes.find((demande) => demande.ordre == ordre)));
    //console.log('maintenance', maintenance);

    const getFirstEchange = (echanges) => {
        if (echanges) {
            return moment(echanges[0].d_jour, 'YYYY/MM/DD').format('DD/MM/YYYY') + ' à ' + echanges[0].d_heure;
        } else {
            return '';
        }
    };
    const getLastEchange = (echanges) => {
        if (echanges.length === 1) return '';
        return moment(echanges[echanges.length - 1].d_jour, 'YYYY/MM/DD').format('DD/MM/YYYY') + ' à ' + echanges[echanges.length - 1].d_heure;
    };

    const createMarkup = (content) => {
        return { __html: content };
    };
    const handleUserType = (user) => {
        if (user.trim().length > 3) return USERTYPE_CLIENT;

        return USERTYPE_EMPLOYE;
    };

    /****Use Effect innit comoponent */
    useEffect(() => {
        setLoad(load + 1);

        /*** Raz notif */
        if (demande && demande.dh_cloture.trim() !== '' && !demande.vuPar.trim().include) {
            (async () => {
                const dataUpdate = { person: account.person, raz: true };
                const razNotif = await axios({
                    method: 'PUT',
                    url: API_PUT_VUE(idUser, ordre),
                    headers: {
                        apiclef: API_KEY,
                        authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                    data: dataUpdate,
                });
            })();
        }
    }, []);

    useEffect(() => {
        if (finDePage.current) {
            console.log('fin de page ok');
            finDePage.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'end',
            });
        }
    }, [finDePage.current]);

    useEffect(() => {
        /** appel Api détail de la demande */
        dipatch(setLoaderVisible());
        try {
            (async () => {
                const demande = await appelApiGet(API_GET_DETAIl_DEMANDE(ordre, idUser));

                if (!demande.data.existe) {
                    toast('Erreur lors de la récupération du ticket numéro ' + ordre, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        type: toast.TYPE.ERROR,
                        autoClose: 5000,
                    });
                    history.push('/demandes');
                } else {
                    console.log('detail demande ', ordre, demande.data.reponse);
                    const detaildemande = demande.data.reponse;
                    setDemande(detaildemande);

                    /** Raz notif */
                    if (detaildemande.dh_cloture.trim() == '' && !detaildemande.vuPar.trim().includes(account.person)) {
                        const dataUpdate = { person: account.person.trim(), raz: true };
                        const razNotif = await axios({
                            method: 'PUT',
                            url: API_PUT_VUE(idUser, ordre),
                            headers: {
                                apiclef: API_KEY,
                                authorization: 'Bearer ' + localStorage.getItem('token'),
                            },
                            data: dataUpdate,
                        });

                        console.log('razNotif', razNotif);
                        if (razNotif.data.ok) dipatch(demandeMajVu(account.person.trim(), ordre));
                    }
                }
            })();
        } catch (error) {
            toast('Erreur lors de la récupération du ticket numéro ' + ordre, {
                position: toast.POSITION.BOTTOM_RIGHT,
                type: toast.TYPE.ERROR,
                autoClose: 5000,
            });
            history.push('/demandes');
        }
        dipatch(setLoaderHidden());
    }, [load]);

    useEffect(() => {
        if (!demande) return;
        setdate_cloture(demande.dh_cloture && demande.dh_cloture.trim() !== '' ? moment(demande.dh_cloture.trim(), 'YYYYMMDDHHmm').format('DD/MM/YYYY HH:mm') : '');
        setdate_demande(getFirstEchange(demande.echanges));
        setdate_reponse(getLastEchange(demande.echanges));
        setfilesItem(demande.piecesJointes && demande.piecesJointes.length > 0 ? demande.piecesJointes : []);
    }, [demande]);

    /** Fonction des evenements */

    const handleClickFile = async (cheminFic64, nomFichier) => {
        console.log('cheminFic64', cheminFic64);
        try {
            const doc = await appelApiGet(API_GET_DOCUMENT(cheminFic64));
            console.log('doc  appelApiGet', doc);

            if (doc.data.existe) {
                File.downloadFile(Buffer.from(doc.data.reponse.data).toString(), nomFichier.trim(), null, true);
            } else {
                toast.error('Erreur lors de la récupération du fichier ' + nomFichier, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000,
                });
            }
        } catch (error) {
            console.log('CATCH doc  appelApiGet', error);
            toast.error('Erreur lors de la récupération du fichier ' + nomFichier, {
                position: toast.POSITION.BOTTOM_RIGHT,
                type: toast.TYPE.ERROR,
                autoClose: 5000,
            });
        }
    };

    const handleSendForm = async (event) => {
        dipatch(setLoaderVisible());
        event.preventDefault();
        let err = [];
        //let dataUpdate = { maintenanceLig: {}, fichiers: [] };

        /// Contrôle des données
        if (!commentaire) {
            toast.error('Veuillez ajouter un commentaire pour valider la demande', {
                position: toast.POSITION.BOTTOM_RIGHT,
                type: toast.TYPE.ERROR,
                autoClose: 5000,
            });

            dipatch(setLoaderHidden());

            return;
        }

        // *********************Partie 'MaintenanceLig' **********************
        // dataUpdate.maintenanceLig.d_jour = moment().format('YYYY/MM/DD'); 
        // dataUpdate.maintenanceLig.d_heure = moment().format('HH:mm'); //h:mm:ss.SSS
        // dataUpdate.maintenanceLig.person = account.person;
        // dataUpdate.maintenanceLig.ordre = ordre;
        // dataUpdate.maintenanceLig.flag = 1;
        // dataUpdate.maintenanceLig.question = commentaire;

        /// Partie document/file
        console.log('Recup fichier', form_filePond);
        // const fichiers = [] ; 
        // if (form_filePond && form_filePond.length > 0) {
        //     form_filePond.forEach((file) => {
        //         fichiers.push({
        //             bufferFic: file.getFileEncodeBase64String(),
        //             nomFic: file.filenameWithoutExtension,
        //             extension: file.fileExtension,
        //         });
        //     });
        // }

        //********************* Echange dans ta table des mails, maintenance_lig plus utilisé ******************** */
        const dateJour = moment().format('YYYY/MM/DD');  
        const heure =moment().format('HH:mm') ;
        const body = {
            question: commentaire  ,
            fichiers: form_filePond && form_filePond.length ? form_filePond.map((file) => ({ bufferFic: file.getFileEncodeBase64String(), nom: file.filename })) : [],
            //fichiers: fichiers,
            expediteur:  account.person.trim() ,
            mail:  account.mail.trim() ,
            tel:  account.tel.trim() ,
            module: demande.c_localisation.trim(),
            t_appel: demande.c_typeAppel.trim(),
            c_cli: account.c_cli.trim() ,
            c_logiciel: demande.produit.trim(), 
            objet: `RE: Demande N°${ordre} du ${date_demande} - ${demande.objet}`,
            person: account.person.trim(),
            idTicket: ordre.trim()

        };
        console.log('body', body);

        const putDemande = await axios({
            // method: 'PUT',
            // url: API_PUT_DETAIL_DEMANDE(idUser),
            method: 'POST',
                url: API_POST_DEMANDE(account.code_acces.trim()),
            headers: {
                apiclef: API_KEY,
                authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            data: body,
        });

        if (!putDemande.data.ok) {
            toast.error("Problème lors de l'ajout du commentaire ", {
                position: toast.POSITION.BOTTOM_RIGHT,
                type: toast.TYPE.ERROR,
                autoClose: 5000,
            });
            
        } else {
            // Maj des info du ticket sans actualiser
            setLoad(load + 1);
            setCommentaire('');
            setForm_filePond();
            toast.success('Votre demande a été ajoutée avec succès ', {
                position: toast.POSITION.BOTTOM_RIGHT,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
            });
        }
        dipatch(setLoaderHidden());
    };


    // Cloture demande API

    const clotureDemande = async (event) =>{
        dipatch(setLoaderVisible());
        event.preventDefault();

        const body = {tabTicket:[ordre]}
        console.log('body clotureDemande', body);

        const putCloture = await axios({
            method: 'PUT',
                url: API_PUT_DEMANDE_CLOTURE(account.code_acces.trim()),
            headers: {
                apiclef: API_KEY,
                authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            data: body,
        });

        if (!putCloture.data.ok){
            toast.error(putCloture.data.erreur_message != ""  ? putCloture.data.erreur_message  : "Impossible de clotuer le ticket " + ordre, {
                position: toast.POSITION.BOTTOM_RIGHT,
                type: toast.TYPE.ERROR,
                autoClose: 5000,});
                console.log('stRetour putCloture', putCloture);
                setOpen(false)
                dipatch(setLoaderHidden());

        }else {
            toast.success(`Le ticket ${ordre} a été cloturé`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
            });
            dipatch(setLoaderHidden());
            window.location.reload(true)
        }
        

    }
    return (
        <>
            {demande && (
                <>
                    <Grid container spacing={1} className="container-demandes ">
                        {/*************************** En tete ******************************************** */}
                        <Grid item xs={12} className="fixe">
                            <h1 className="titre-h1">
                                {' '}
                                <span onClick={(e) => history.push('/demandes')} className="back-icon">
                                    {' '}
                                    Mes demandes
                                </span>{' '}
                                {' > '}{ordre}: {demande.objet}
                            </h1>
                        </Grid>{' '}
                        {/* titre de page 
                    <Grid item xs={12} lg={2}>
                        <Button onClick={(e) => history.push('/demandes')} variant="outlined" color="primary" fullWidth>
                            Revenir aux demandes
                        </Button>
                    </Grid>*/}
                        {/*************************** En tete ******************************************** */}
                        <Grid item xs={12}>
                            <Accordion className="Accordion">
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Typography className="typo-accordeon">
                                                <QuizOutlinedIcon className="logo" color="primary" /> Informations générales
                                            </Typography>
                                        </Grid>
                                        <Grid item>{date_cloture && date_cloture !== '' && <Typography color="secondary" variant="h6">{`(clôturé le ${date_cloture})`}</Typography>}</Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Typography component="span">Société :</Typography>{' '}
                                                    <Typography component="span" className="span-info">
                                                        {demande.societe}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography component="span">Produit :</Typography>{' '}
                                                    <Typography component="span" className="span-info">
                                                        {demande.produit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography component="span">Type d'appel :</Typography>{' '}
                                                    <Typography component="span" className="span-info">
                                                        {demande.typeAppel}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography component="span">Localisation :</Typography>{' '}
                                                    <Typography component="span" className="span-info">
                                                        {demande.localisation}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Typography component="span">Demande du </Typography>{' '}
                                                    <Typography component="span" className="span-info">
                                                        {date_demande}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    {
                                                        /* date_reponse !== '' && */ <React.Fragment>
                                                            <Typography component="span">Dernière réponse le :</Typography>{' '}
                                                            <Typography component="span" className="span-info">
                                                                {date_reponse}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        {/************************************ Echange ******************************************************** */}
                        <Grid item xs={12}>
                            <Accordion className="Accordion" defaultExpanded={true}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography className="typo-accordeon">
                                        <QuestionAnswerOutlinedIcon className="logo" color="primary" /> Conversation
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {demande.echanges &&
                                            demande.echanges.map((echange, index) => {
                                                if (echange.person.trim() === 'TEC') return null;
                                                return (
                                                    <Grid item xs={12} key={index}>
                                                        <Grid container justifyContent={handleUserType(echange.person, techniciens) ? 'flex-start' : 'flex-end'}>
                                                            <Grid item xs={12} sm={11} md={10} lg={9}>
                                                                <Grid container spacing={1} className={handleUserType(echange.person, techniciens) ? 'HERMESS_conversEmploye' : 'HERMESS_conversClient ' + (echange.echtype == "100" ? "HERMESS_conversClientMail" : "")}>
                                                                    <Grid item xs={12}>
                                                                        <Grid container spacing={2} className="HERMESS_conversClient_title">
                                                                            <Grid item>{handleUserType(echange.person, techniciens) ? <RecordVoiceOverIcon /> : <PersonIcon />}</Grid>
                                                                            <Grid item>
                                                                                <Typography className="HERMESS_conversClient_titleName" variant="subtitle1">
                                                                                    {echange.nom}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography variant="overline">
                                                                                    {moment(echange.d_jour, 'YYYY/MM/DD').format('DD/MM/YYYY')} {echange.d_heure}
                                                                                </Typography>
                                                                            </Grid>
                                                                            {echange.echtype == "100" &&(
                                                                                <Tooltip title="Message en attente de tratement par le support" placement='bottom'>
                                                                                    <Grid item>  <AccessTime/> </Grid>
                                                                                </Tooltip>
                                                                                )}
                                                                                
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item xs={12}>
                                                                        <div dangerouslySetInnerHTML={createMarkup(echange.question.replaceAll('\r\n', '<br />'))} className="HERMESS_conversClient_comment"></div>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        {/*************************************************************************** ged Demande  **************************************/}
                        <Grid item xs={12}>
                            {date_cloture === '' && (
                                <Accordion className="Accordion">
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className="typo-accordeon">
                                            {' '}
                                            <AttachEmailOutlinedIcon className="logo" color="primary" /> Liste des documents associés à la demande
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {/* <Grid container spacing={1}>
                                        {filesItem &&
                                            filesItem.map((file, index) => {
                                                return (
                                                    <Grid item xs={12} lg={6} key={index}>
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item>
                                                                <Typography>
                                                                    {' '}
                                                                    <Button startIcon={<GetAppIcon />} onClick={(e) => handleClickFile(file.cheminAbsFichier, file.nomFichier)} variant="contained" color="primary">
                                                                        Visualiser
                                                                    </Button>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography>{file.nomFichier}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            }) }
                                        </Grid> */}
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} lg={8}>
                                                {filesItem && <TableFichier files={filesItem} handleClickFile={handleClickFile}></TableFichier>}
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            )}
                        </Grid>
                        {/*************************************************************************** Poursuivre echange  **************************************/}
                        <Grid item xs={12}>
                            {date_cloture === '' && (
                                <Accordion className="Accordion" defaultExpanded>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className="typo-accordeon">
                                            {' '}
                                            <DraftsOutlinedIcon color="primary" className="logo" /> Poursuivre l'échange
                                        </Typography>
                                    </AccordionSummary>
                                    <form>
                                        <AccordionDetails>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <TextField value={commentaire} onChange={(e) => setCommentaire(e.target.value)} minRows={5} multiline fullWidth required size="small" variant="outlined" type="email" label="Commentaire"></TextField>
                                                </Grid>
                                                <Grid item xs={12} style={{ marginTop: '25px' }}>
                                                    <FilePond className="HERMESS_mainFile" files={form_filePond} onupdatefiles={setForm_filePond} allowMultiple multiple={true} allowFileEncode={true} server={null} name="mainfile" labelIdle="Déposer votre fichier ici" credits={''} />
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                        <AccordionActions>
                                        <Button startIcon={<HttpsOutlined />} onClick={(e) => setOpen(true)} variant="contained" color="secondary">
                                                Cloturer
                                            </Button>
                                            <Button startIcon={<SendIcon />} onClick={(e) => handleSendForm(e)} variant="contained" color="primary">
                                                Envoyer
                                            </Button>

                                        </AccordionActions>
                                    </form>
                                </Accordion>
                            )}
                        </Grid>
                        <div id="blanc-fin" />
                    </Grid>
                    {/** Pop up cloture demande */}
                    <div>
                        <Dialog
                            open={open}
                            onClose={() => setOpen(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {" Confirmez-vous la fermeture du ticket n° " + ordre + " ?"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Si vous fermez le ticket {ordre}, vous ne pourrez plus apporter de modification. Cependant vous pourrez toujours le consulter.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={(e) => clotureDemande(e)}>Cloturer</Button>
                                <Button onClick={() => setOpen(false)} autoFocus>
                                    Annuler
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>

                    <div ref={finDePage} id="finPage" />
                </>
            )}
        </>
    );
};

export default DemandeDetail;
