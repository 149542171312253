import * as types from './actionTypes/mailsAction';

export const mailRequeste = (idClient, mail, c_soc, query) => ({
    type: types.MAIL_REQUEST,
    idClient,
    mail,
    c_soc,
    query,
});

export const mailSucess = (mails) => ({
    type: types.MAIL_SUCCESS,
    mails,
});

export const mailAdd = (newMail) => ({
    type: types.MAIL_ADD,
    newMail,
});

export const mailError = (err) => ({
    type: types.MAIL_ERROR,
    err,
});

export const clearMessages = () => ({
    type: types.CLEAR_MESSAGES,
});

export const mailUpdatePagination = (pagination) => ({
    type: types.MAIL_UPDATE_PAGINATION,
    pagination,
});

export const mailUpdateFiltres = (filtres) => ({
    type: types.MAIL_UPDATE_FILTRE,
    filtres,
});
