import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { API_KEY, API_CHECK_LOGIN, API_GET_MAIL_ATTENTE } from '../../../config/config';
import { mailSucess } from '../../../redux/actions/actionMails';
import { push } from 'connected-react-router';
import * as types from '../../actions/actionTypes/mailsAction';

const getMails = (idClient, mail, c_soc, query) => {
    return axios({
        method: 'get',
        url: `${API_GET_MAIL_ATTENTE(idClient, mail, c_soc) + query}`,
        headers: {
            apiclef: API_KEY,
            authorization: 'Bearer ' + localStorage.getItem('token'),
        },
    });
};

export function* workerMails(action) {
    try {
        const response = yield call(getMails, action.idClient, action.mail, action.c_soc, action.query);

        if (response.data.existe) {
            const mails = response.data.reponse;

            // IMPORTANT :  permet d'initialiser les data après refresh
            localStorage.setItem('mails', JSON.stringify(mails));

            yield put(mailSucess(mails));
        } else {
            yield put({
                type: types.MAIL_ERROR,
                error: `Aucun mail n'est disponnible avec ces filtres`, //response.data.erreur_message,
            });
        }
    } catch (error) {
        console.log('ERROR catch mail get', error);
        yield put({
            type: types.MAIL_ERROR,
            error: 'Catch ERROR ' + error,
        });
    }
}

export function* watcherMails() {
    yield takeLatest(types.MAIL_REQUEST, workerMails);
}
