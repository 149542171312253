export const DEMANDE_REQUEST = 'DEMANDE_REQUEST';
export const DEMANDE_SUCCESS = 'DEMANDE_SUCCESS';
export const DEMANDE_ERROR = 'DEMANDE_ERROR';

export const GET_DEMANDE_REQUEST = 'GET_DEMANDE_REQUEST';
export const GET_DEMANDE_SUCCESS = 'GET_DEMANDE_SUCCESS';
export const GET_DEMANDE_ERROR = 'GET_DEMANDE_ERROR';
export const DEMANDE_MODIF_VU = 'GET_DEMANDE_MODIF_VU';

export const INIT_MOD_AUTH = 'INIT_MOD_AUTH';

export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export const DEMANDE_UPDATE_PAGINATION = 'UPDATE_PAGINATION';
export const DEMANDE_UPDATE_FILTRE = 'DEMANDE_UPDATE_FILTRE';
