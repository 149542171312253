import * as types from './actionTypes/auth';

export const loginRequest = (username, password) => ({
    type: types.LOGIN_REQUEST,
    username,
    password,
});

export const loginSuccess = (account, token) => ({
    type: types.LOGIN_SUCCESS,
    account,
    token,
});

export const loginError = (err) => ({
    type: types.LOGIN_ERROR,
    err,
});

export const clearMessages = () => ({
    type: types.CLEAR_MESSAGES,
});

export const initModAuth = () => ({
    type: types.INIT_MOD_AUTH,
});

export const appDeconnect = () => ({
    type: types.DECONNECT,
});
