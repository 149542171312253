import { Grid, Button, Typography } from '@mui/material';
import { GetApp as GetAppIcon, ArrowDropUp as ArrowDropUpIcon } from '@mui/icons-material';
import { Subject, PictureAsPdf, Image, Code, Movie, Gif, ViewList, FormatAlignJustify, DataUsage } from '@mui/icons-material';
import { styled } from '@mui/styles';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';

const tabExt = [
    {
        appl: 'text/html',
        type: 'html',
        icon: <Code fontSize="small" className="icon-fichier" />,
    },
    {
        appl: 'text/plain',
        type: 'txt',
        icon: <FormatAlignJustify fontSize="small" className="icon-fichier" />,
    },
    {
        appl: 'application/pdf',
        type: 'pdf',
        icon: <PictureAsPdf fontSize="small" className="icon-fichier" />,
    },
    {
        appl: 'image/gif',
        type: 'gif',
        icon: <Gif fontSize="small" className="icon-fichier" />,
    },
    {
        appl: 'image/jpeg',
        type: 'jpg',
        icon: <Image fontSize="small" className="icon-fichier" />,
    },
    {
        appl: 'image/png',
        type: 'png',
        icon: <Image fontSize="small" className="icon-fichier" />,
    },
    {
        appl: 'video/mpeg',
        type: 'mpg',
        icon: <Movie fontSize="small" className="icon-fichier" />,
    },
    {
        appl: 'application/vnd.ms-excel',
        type: 'xls',
        icon: <ViewList fontSize="small" className="icon-fichier" />,
    },
    {
        appl: 'application/pdf',
        type: 'dpl',
        icon: <PictureAsPdf fontSize="small" className="icon-fichier" />,
    },
    {
        appl: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        type: 'docx',
        icon: <Subject fontSize="small" className="icon-fichier" />,
    },
    {
        appl: 'application/octet-stream',
        type: 'bin',
        icon: <DataUsage fontSize="small" className="icon-fichier" />,
    },
];

const returnLogoExt = (file) => {
    const ext = file.nomFichier.trim().split('.')[1];
    let ind;

    if(ext != undefined)
    {
        ind = tabExt.findIndex((element) => element.type == ext.toLowerCase()); // on vérifie que l'extension existe dans notre tableau d'extension ext.toLowerCase()
    }
    else
    {
        ind = -1;
    }

    console.log('ind', ind);
    ind = ind != -1 ? ind : tabExt.length - 1;

    return tabExt[ind].icon;
};

const TableFichier = ({ files, handleClickFile }) => {
    console.log('files', files);

    return (
        <>
            {files && (
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="simple table">
                        <TableBody>
                            {files.map((file, index) => (
                                /* <div className="file">
                    
                            <Typography className="typo" color="#e63946">
                                {returnLogoExt(file)}
                            </Typography>
                            <Typography className="typo">{file.nomFichier}</Typography>

                            <Button onClick={(e) => handleClickFile(file.cheminAbsFichier, file.nomFichier)}>
                                <GetAppIcon color="#457b9d" />
                            </Button>
                            </>
                        </div> */
                                <TableRow key={index} className="row-table">
                                    <TableCell align="left">
                                        <Typography className="typo" color="#e63946">
                                            {returnLogoExt(file)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">{file.nomFichier}</TableCell>
                                    <TableCell align="left">
                                        <Button onClick={(e) => handleClickFile(file.cheminAbsFichier, file.nomFichier)}>
                                            <GetAppIcon color="#457b9d" />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};

export default TableFichier;
