import React from 'react';
import SVG_LOADER from '../../assets/images/svg/loader_square.svg';
import PropTypes from 'prop-types';

const MaskLoader = ({ visible }) => {
    return (
        <React.Fragment>
            { visible &&
                <div className="fullLoader">
                    <div className="maskLoader"></div>
                    <img src={SVG_LOADER} alt="loader" />
                </div>

            }
        </React.Fragment>
    )
}

MaskLoader.propTypes = {
    visible: PropTypes.bool,
};

export default MaskLoader;
